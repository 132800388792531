import { useState, useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { 
  ArrowLeft, Activity, Clock, CheckCircle2, XCircle, Mail, 
  Pencil, ExternalLink, Globe, ArrowRight, Code, MousePointer, 
  RotateCw, AlertCircle, ChevronRight 
} from "lucide-react";
import { Link } from "react-router-dom";
import { formsService } from "@/services/forms/index";
import { DashboardLayout } from "@/components/DashboardLayout";
import TestResultsTable from "@/components/TestResultsTable";
import { Badge } from "@/components/ui/badge";
import EditFormDialog from "@/components/EditFormDialog";
import { Progress } from "@/components/ui/progress";
import { Separator } from "@/components/ui/separator";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { useToast } from "@/hooks/use-toast";

interface Stats {
  avgResponseTime: number;
  responseTimeTrend: number;
  successRate: number;
  successRateTrend: number;
  totalTests: number;
  totalTestsTrend: number;
}

interface TestResult {
  id: string;
  status: string;
  test_duration: number;
  created_at: Date;
  response_code?: number;
}

const calculateStats = (testResults: TestResult[]): Stats => {
  const defaultStats = {
    avgResponseTime: 0,
    responseTimeTrend: 0,
    successRate: 0,
    successRateTrend: 0,
    totalTests: 0,
    totalTestsTrend: 0
  };

  if (!Array.isArray(testResults) || testResults.length === 0) {
    return defaultStats;
  }

  try {
    const recentTests = testResults.slice(0, 100);
    const prevTests = testResults.slice(100, 200);
    
    // Calculate average response time (only for successful tests with valid duration)
    const validDurations = recentTests.filter(test => 
      test?.status === 'success' && 
      typeof test?.test_duration === 'number' && 
      test.test_duration >= 0 && 
      test.test_duration < 30000 // Exclude timeouts
    );
    
    const totalDuration = validDurations.reduce((sum, test) => sum + test.test_duration, 0);
    const avgResponseTime = validDurations.length > 0 ? Math.round(totalDuration / validDurations.length) : null;

    // Calculate response time trend (only for valid durations)
    const prevValidDurations = prevTests.filter(test => 
      test?.status === 'success' && 
      typeof test?.test_duration === 'number' && 
      test.test_duration >= 0 && 
      test.test_duration < 30000
    );
    
    const prevTotalDuration = prevValidDurations.reduce((sum, test) => sum + test.test_duration, 0);
    const prevAvgResponseTime = prevValidDurations.length > 0 ? 
      Math.round(prevTotalDuration / prevValidDurations.length) : 
      null;
    
    const responseTimeTrend = (prevAvgResponseTime && avgResponseTime) ? 
      Math.round(((avgResponseTime - prevAvgResponseTime) / prevAvgResponseTime) * 100) : 
      0;

    // Calculate success rate (considering error and failure status)
    const successCount = recentTests.filter(test => test?.status === 'success').length;
    const failureCount = recentTests.filter(test => 
      test?.status === 'error' || 
      test?.status === 'failure' ||
      (test?.response_code && test.response_code >= 400)
    ).length;
    
    const successRate = recentTests.length > 0 ? 
      Math.round((successCount / (successCount + failureCount)) * 100) : 
      0;

    // Calculate success rate trend
    const prevSuccessCount = prevTests.filter(test => test?.status === 'success').length;
    const prevFailureCount = prevTests.filter(test => 
      test?.status === 'error' || 
      test?.status === 'failure' ||
      (test?.response_code && test.response_code >= 400)
    ).length;
    
    const prevSuccessRate = prevTests.length > 0 ? 
      Math.round((prevSuccessCount / (prevSuccessCount + prevFailureCount)) * 100) : 
      successRate;
    
    const successRateTrend = prevSuccessRate > 0 ? 
      Math.round(((successRate - prevSuccessRate) / prevSuccessRate) * 100) : 
      0;

    // Calculate total tests trend
    const totalTestsTrend = prevTests.length > 0 ? 
      Math.round(((recentTests.length - prevTests.length) / prevTests.length) * 100) : 
      0;

    // Debug logging
    console.log('Form Stats:', {
      tests: {
        total: testResults.length,
        recent: recentTests.length,
        prev: prevTests.length
      },
      durations: {
        valid: validDurations.length,
        total: totalDuration,
        average: avgResponseTime,
        trend: responseTimeTrend,
        samples: validDurations.map(t => ({
          id: t.id,
          duration: t.test_duration,
          created_at: t.created_at
        }))
      },
      success: {
        current: {
          total: recentTests.length,
          success: successCount,
          failure: failureCount,
          rate: successRate
        },
        prev: {
          total: prevTests.length,
          success: prevSuccessCount,
          failure: prevFailureCount,
          rate: prevSuccessRate
        },
        trend: successRateTrend
      }
    });

    return {
      avgResponseTime: avgResponseTime || 0,
      responseTimeTrend,
      successRate,
      successRateTrend,
      totalTests: testResults.length,
      totalTestsTrend
    };
  } catch (error) {
    console.error('Error calculating stats:', error);
    return defaultStats;
  }
};

const FormDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (!id) {
      toast({
        title: "Error",
        description: "No form ID provided",
        variant: "destructive"
      });
      navigate('/');
      return;
    }
  }, [id, navigate, toast]);

  const { data: form, isLoading: isFormLoading, error: formError } = useQuery({
    queryKey: ["form", id],
    queryFn: () => formsService.getForm(id),
    enabled: !!id,
    staleTime: 30000,
    retry: 2,
  });

  const { data: testResults = [], isLoading: isResultsLoading } = useQuery({
    queryKey: ["form-results", id],
    queryFn: () => formsService.getFormResults(id),
    enabled: !!id,
    staleTime: 30000,
  });

  if (formError) {
    console.error('Error fetching form:', formError);
    toast({
      title: "Error",
      description: "The requested form could not be found or you don't have access to it.",
      variant: "destructive"
    });
    navigate('/');
  }

  return (
    <DashboardLayout>
      {(isFormLoading || isResultsLoading) ? (
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin">
            <RotateCw className="w-6 h-6" />
          </div>
        </div>
      ) : (
        <div>
          {form && (
            <div>
              {/* Header */}
              <div className="sticky top-0 z-40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
                <div className="border-b">
                  <div className="flex h-14 items-center px-6">
                    <Link to="/dashboard" className="mr-4">
                      <Button variant="ghost" size="icon" className="h-8 w-8">
                        <ArrowLeft className="h-4 w-4" />
                      </Button>
                    </Link>
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <Link to="/dashboard" className="hover:text-foreground transition-colors">Forms</Link>
                      <ChevronRight className="h-4 w-4" />
                      <span className="text-foreground font-medium">{form.name}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Main Content */}
              <div className="container mx-auto py-6 space-y-6">
                {/* Form Header */}
                <div className="flex flex-col gap-4 md:flex-row md:items-start md:justify-between">
                  <div className="space-y-1">
                    <h1 className="text-2xl font-semibold tracking-tight flex items-center gap-3">
                      {form.name}
                      <Badge 
                        variant={form.health.toString() === "healthy" ? "default" : "destructive"}
                        className="h-6"
                      >
                        {form.health.toString() === "healthy" ? (
                          <CheckCircle2 className="h-3.5 w-3.5 mr-1.5" />
                        ) : (
                          <XCircle className="h-3.5 w-3.5 mr-1.5" />
                        )}
                        {form.health.toString() === "healthy" ? "Healthy" : "Failed"}
                      </Badge>
                    </h1>
                    <div className="flex items-center gap-3 text-sm text-muted-foreground">
                      <div className="flex items-center gap-1.5">
                        <Globe className="h-4 w-4" />
                        <a 
                          href={form.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:text-foreground transition-colors"
                        >
                          {new URL(form.url).hostname}
                        </a>
                      </div>
                      <Separator orientation="vertical" className="h-4" />
                      <div className="flex items-center gap-1.5">
                        <Clock className="h-4 w-4" />
                        <span>Every {form.test_interval} minutes</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-3">
                    <Button onClick={() => {}} variant="secondary" size="sm" className="h-9">
                      <RotateCw className="h-4 w-4 mr-2" />
                      Run Test
                    </Button>
                    <Button
                      size="sm"
                      variant="default"
                      className="h-9"
                      onClick={() => setShowEditDialog(true)}
                    >
                      <Pencil className="h-4 w-4 mr-2" />
                      Edit Form
                    </Button>
                  </div>
                </div>

                {/* Quick Stats */}
                <div className="grid gap-4 md:grid-cols-4">
                  <Card className="bg-background hover:bg-accent/5 transition-colors">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium text-muted-foreground">Health Status</CardTitle>
                      {form.health.toString() === "healthy" ? (
                        <CheckCircle2 className="h-4 w-4 text-emerald-600 dark:text-emerald-400" />
                      ) : (
                        <XCircle className="h-4 w-4 text-rose-600 dark:text-rose-400" />
                      )}
                    </CardHeader>
                    <CardContent>
                      <div className="flex items-center gap-2">
                        <div className="text-2xl font-bold text-foreground">
                          {form.health.toString() === "healthy" ? "Healthy" : "Failed"}
                        </div>
                      </div>
                      <div className="mt-3 flex items-center gap-2">
                        <Progress 
                          value={form.health.toString() === "healthy" ? 100 : 0} 
                          className={cn(
                            "h-2",
                            form.health.toString() === "healthy" ? "bg-emerald-600/20" : "bg-rose-600/20"
                          )}
                        />
                        <span className="text-xs text-muted-foreground min-w-[3ch]">
                          {form.health.toString() === "healthy" ? "100%" : "0%"}
                        </span>
                      </div>
                      <p className="text-xs text-muted-foreground mt-2">
                        Last checked {isNaN(new Date(form.last_tested).getTime()) ? 'N/A' : format(new Date(form.last_tested), 'PP HH:mm')}
                      </p>
                    </CardContent>
                  </Card>

                  <Card className="bg-background hover:bg-accent/5 transition-colors">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium text-muted-foreground">Response Time</CardTitle>
                      <Activity className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <div className="flex items-center gap-2">
                        <div className="text-2xl font-bold text-foreground">
                          {calculateStats(testResults).avgResponseTime ? `${calculateStats(testResults).avgResponseTime}ms` : 'N/A'}
                        </div>
                        {calculateStats(testResults).responseTimeTrend !== 0 && (
                          <Badge 
                            variant={calculateStats(testResults).responseTimeTrend < 0 ? "destructive" : "default"} 
                            className="h-5"
                          >
                            {calculateStats(testResults).responseTimeTrend > 0 ? "+" : ""}{calculateStats(testResults).responseTimeTrend}%
                          </Badge>
                        )}
                      </div>
                      <div className="mt-3 flex items-center gap-2">
                        <Progress 
                          value={Math.min(100, Math.max(0, 100 - (calculateStats(testResults).avgResponseTime / 10)))} 
                          className={cn(
                            "h-2",
                            calculateStats(testResults).avgResponseTime < 300 ? "bg-emerald-600/20" : 
                            calculateStats(testResults).avgResponseTime < 1000 ? "bg-yellow-600/20" : 
                            "bg-rose-600/20"
                          )}
                        />
                        <span className="text-xs text-muted-foreground min-w-[3ch]">
                          {Math.min(100, Math.max(0, 100 - (calculateStats(testResults).avgResponseTime / 10)))}%
                        </span>
                      </div>
                      <p className="text-xs text-muted-foreground mt-2">
                        Average over last 100 tests
                      </p>
                    </CardContent>
                  </Card>

                  <Card className="bg-background hover:bg-accent/5 transition-colors">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium text-muted-foreground">Success Rate</CardTitle>
                      <CheckCircle2 className="h-4 w-4 text-emerald-600 dark:text-emerald-400" />
                    </CardHeader>
                    <CardContent>
                      <div className="flex items-center gap-2">
                        <div className={cn(
                          "text-2xl font-bold text-foreground",
                          calculateStats(testResults).successRate >= 95 ? "text-emerald-600 dark:text-emerald-400" :
                          calculateStats(testResults).successRate >= 80 ? "text-yellow-600 dark:text-yellow-400" :
                          "text-rose-600 dark:text-rose-400"
                        )}>
                          {calculateStats(testResults).successRate}%
                        </div>
                        {calculateStats(testResults).successRateTrend !== 0 && (
                          <Badge 
                            variant={calculateStats(testResults).successRateTrend > 0 ? "default" : "destructive"} 
                            className="h-5"
                          >
                            {calculateStats(testResults).successRateTrend > 0 ? "+" : ""}{calculateStats(testResults).successRateTrend}%
                          </Badge>
                        )}
                      </div>
                      <div className="mt-3 flex items-center gap-2">
                        <Progress 
                          value={calculateStats(testResults).successRate} 
                          className={cn(
                            "h-2",
                            calculateStats(testResults).successRate >= 95 ? "bg-emerald-600/20" :
                            calculateStats(testResults).successRate >= 80 ? "bg-yellow-600/20" :
                            "bg-rose-600/20"
                          )}
                        />
                        <span className="text-xs text-muted-foreground min-w-[3ch]">
                          {calculateStats(testResults).successRate}%
                        </span>
                      </div>
                      <p className="text-xs text-muted-foreground mt-2">
                        Based on last 100 tests
                      </p>
                    </CardContent>
                  </Card>

                  <Card className="bg-background hover:bg-accent/5 transition-colors">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium text-muted-foreground">Total Tests</CardTitle>
                      <Activity className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <div className="flex items-center gap-2">
                        <div className="text-2xl font-bold text-foreground">{calculateStats(testResults).totalTests.toLocaleString()}</div>
                        {calculateStats(testResults).totalTestsTrend !== 0 && (
                          <Badge 
                            variant={calculateStats(testResults).totalTestsTrend > 0 ? "default" : "destructive"} 
                            className="h-5"
                          >
                            {calculateStats(testResults).totalTestsTrend > 0 ? " + " : ""}{calculateStats(testResults).totalTestsTrend}%
                          </Badge>
                        )}
                      </div>
                      <div className="mt-3 flex items-center gap-2">
                        <Progress 
                          value={Math.min(100, (calculateStats(testResults).totalTests / (form?.test_interval ? (43200 / form.test_interval) : 1440)) * 100)} 
                          className="h-2 bg-muted/20" 
                        />
                        <span className="text-xs text-muted-foreground min-w-[3ch]">
                          {Math.min(100, Math.round((calculateStats(testResults).totalTests / (form?.test_interval ? (43200 / form.test_interval) : 1440)) * 100))}%
                        </span>
                      </div>
                      <p className="text-xs text-muted-foreground mt-2">
                        Tests run this month
                      </p>
                    </CardContent>
                  </Card>
                </div>

                {/* Form Configuration */}
                <Card className="bg-background">
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <div className="space-y-1">
                        <CardTitle>Form Configuration</CardTitle>
                        <p className="text-sm text-muted-foreground">
                          Endpoint and selector configuration details
                        </p>
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div className="grid gap-6 md:grid-cols-2">
                      <div className="space-y-4">
                        <div>
                          <div className="flex items-center justify-between mb-2">
                            <label className="text-sm font-medium">Form URL</label>
                            <Badge variant="outline" className="text-xs">Required</Badge>
                          </div>
                          <div className="relative group">
                            <div className="rounded-md border bg-muted/40 p-3 group-hover:bg-muted/60 transition-colors">
                              <code className="text-sm break-all block pr-6">
                                {form.url}
                              </code>
                            </div>
                            <div className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
                              <a 
                                href={form.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="p-1 hover:bg-muted rounded-md"
                              >
                                <ExternalLink className="h-4 w-4 text-muted-foreground" />
                              </a>
                            </div>
                          </div>
                        </div>

                        <div>
                          <label className="text-sm font-medium mb-2 block">HTTP Method</label>
                          <Badge variant="secondary" className="h-7">
                            {form.method}
                          </Badge>
                        </div>

                        {form.notification_email && (
                          <div>
                            <label className="text-sm font-medium mb-2 block">Notification Email</label>
                            <div className="flex items-center gap-2 text-muted-foreground">
                              <Mail className="h-4 w-4" />
                              <span className="text-sm">{form.notification_email}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="space-y-4">
                        {form.form_selector && (
                          <div>
                            <div className="flex items-center justify-between mb-2">
                              <label className="text-sm font-medium">Form Selector</label>
                              <Badge variant="outline" className="text-xs">CSS</Badge>
                            </div>
                            <div className="rounded-md border bg-muted/40 p-3 hover:bg-muted/60 transition-colors">
                              <code className="text-sm font-mono break-all">
                                {form.form_selector}
                              </code>
                            </div>
                          </div>
                        )}

                        {form.submit_button_selector && (
                          <div>
                            <div className="flex items-center justify-between mb-2">
                              <label className="text-sm font-medium">Submit Button</label>
                              <Badge variant="outline" className="text-xs">CSS</Badge>
                            </div>
                            <div className="rounded-md border bg-muted/40 p-3 hover:bg-muted/60 transition-colors">
                              <code className="text-sm font-mono break-all">
                                {form.submit_button_selector}
                              </code>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>

                {/* Test Results History */}
                <Card className="bg-background">
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <div className="space-y-1">
                        <CardTitle>Test Results History</CardTitle>
                        <p className="text-sm text-muted-foreground">
                          Recent test executions and their outcomes
                        </p>
                      </div>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div className="-mx-6">
                      <TestResultsTable formId={id!} />
                    </div>
                  </CardContent>
                </Card>
              </div>

              <EditFormDialog
                form={{
                  ...form,
                  form_selector: form.form_selector, 
                  notification_email: form.notification_email,
                  submit_button_selector: form.submit_button_selector
                }}
                open={showEditDialog}
                onOpenChange={setShowEditDialog}
              />
            </div>
          )}
        </div>
      )}
    </DashboardLayout>
  );
};

export default FormDetail;