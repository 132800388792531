import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "@/integrations/supabase/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Navigation } from "@/components/landing/Navigation";

// Use window.location.origin to ensure we're using the current domain
const SITE_URL = window.location.origin;

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        navigate('/dashboard');
      }
    };
    checkUser();
  }, [navigate]);

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <Navigation />
      
      <main className="flex-grow flex items-center justify-center p-4">
        <Card className="w-[400px]">
          <CardHeader>
            <CardTitle className="text-center">
              <div className="flex items-center justify-center">
                <span className="text-2xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
                  Form
                </span>
                <span className="text-2xl font-extrabold bg-gradient-to-r from-purple-600 to-pink-500 bg-clip-text text-transparent">
                  Sure
                </span>
                <div className="w-1.5 h-1.5 rounded-full bg-pink-500 ml-0.5 animate-pulse" />
              </div>
            </CardTitle>
            <CardDescription className="text-center">
              Sign in to monitor your forms
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Auth
              supabaseClient={supabase}
              appearance={{
                theme: ThemeSupa,
                variables: {
                  default: {
                    colors: {
                      brand: 'rgb(147, 51, 234)',
                      brandAccent: 'rgb(126, 34, 206)',
                    },
                  },
                },
                style: {
                  button: {
                    borderRadius: '6px',
                  },
                },
              }}
              providers={['google']}
              view="sign_in"
              showLinks={false}
              redirectTo={`${SITE_URL}/dashboard`}
              onlyThirdPartyProviders={true}
            />
          </CardContent>
        </Card>
      </main>

      <footer className="border-t py-8">
        <div className="container mx-auto px-4 text-center text-muted-foreground">
          Powered by TrueAgents.ai
        </div>
      </footer>
    </div>
  );
};

export default Login;