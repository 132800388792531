import { Activity, AlertCircle, CheckCircle2, Clock } from "lucide-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { formsService } from "@/services/forms/index";
import { useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { TestResult } from "@/types/testResults";
import { Card, CardContent } from "@/components/ui/card";

type RealtimeTestResult = Pick<TestResult, 'form_id' | 'status'>;

const StatsOverview = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    console.log('Setting up real-time listener for test results...');
    const channel = supabase
      .channel('form-test-results')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'form_test_results',
        },
        (payload: RealtimePostgresChangesPayload<RealtimeTestResult>) => {
          console.log('Test results changed:', payload);
          // Force refetch both queries to ensure stats are updated
          queryClient.invalidateQueries({ queryKey: ['forms'] });
          queryClient.invalidateQueries({ queryKey: ['all-test-results'] });
          
          const newRecord = payload.new as RealtimeTestResult;
          if (newRecord?.form_id) {
            console.log('Invalidating form results for:', newRecord.form_id);
            queryClient.invalidateQueries({ 
              queryKey: ['form-results', newRecord.form_id] 
            });
          }
        }
      )
      .subscribe();

    return () => {
      console.log('Cleaning up real-time listener...');
      supabase.removeChannel(channel);
    };
  }, [queryClient]);

  const { data: forms = [], isLoading: isFormsLoading } = useQuery({
    queryKey: ["forms"],
    queryFn: formsService.getForms,
  });

  const { data: allResults = [], isLoading: isResultsLoading } = useQuery({
    queryKey: ["all-test-results"],
    queryFn: formsService.getAllTestResults,
  });

  // Count active forms (forms with tests in the last 24h)
  const now = Date.now();
  const twentyFourHoursAgo = now - 24 * 60 * 60 * 1000;
  
  const activeFormsCount = forms.filter(form => 
    allResults.some(result => 
      result.form_id === form.id && 
      new Date(result.created_at).getTime() > twentyFourHoursAgo
    )
  ).length;

  // Filter successful and failed checks
  const validResults = allResults.filter(result => 
    result && result.status !== null && result.status !== undefined
  );

  const successfulChecks = validResults.filter(result => 
    result.status === "success" && 
    (!result.response_code || result.response_code < 400) // Some successful tests might not have response_code
  ).length;

  const failedChecks = validResults.filter(result => 
    result.status === "error" || 
    result.status === "failure" ||
    (result.response_code && result.response_code >= 400)
  ).length;

  // Calculate last 24h stats
  const recentResults = validResults.filter(result => 
    new Date(result.created_at).getTime() > twentyFourHoursAgo
  );

  const last24hSuccess = recentResults.filter(result => 
    result.status === "success" && 
    (!result.response_code || result.response_code < 400)
  ).length;

  const last24hFailed = recentResults.filter(result => 
    result.status === "error" || 
    result.status === "failure" ||
    (result.response_code && result.response_code >= 400)
  ).length;

  // Calculate average response time (only for successful requests)
  const validSuccessfulResults = validResults.filter(result => 
    result.status === "success" && 
    typeof result.test_duration === 'number' && 
    result.test_duration >= 0 && 
    result.test_duration < 30000 // Exclude timeouts
  );

  const avgResponseTime = validSuccessfulResults.length > 0
    ? Math.round(
        validSuccessfulResults.reduce((acc, curr) => acc + (curr.test_duration || 0), 0) / 
        validSuccessfulResults.length
      )
    : null; // Use null instead of 0 when no valid results

  // Debug logging
  console.log('Stats Debug:', {
    forms: {
      total: forms.length,
      active: activeFormsCount
    },
    results: {
      total: allResults.length,
      valid: validResults.length,
      successful: {
        count: successfulChecks,
        results: validResults.filter(r => r.status === "success").map(r => ({
          id: r.id,
          status: r.status,
          response_code: r.response_code,
          duration: r.test_duration
        }))
      },
      failed: {
        count: failedChecks,
        results: validResults.filter(r => r.status === "error" || r.status === "failure").map(r => ({
          id: r.id,
          status: r.status,
          response_code: r.response_code,
          error: r.error_message
        }))
      }
    },
    last24h: {
      total: recentResults.length,
      successful: last24hSuccess,
      failed: last24hFailed
    },
    responseTime: {
      validResults: validSuccessfulResults.length,
      average: avgResponseTime,
      durations: validSuccessfulResults.map(r => r.test_duration)
    }
  });

  if (isFormsLoading || isResultsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid gap-6 mb-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <Card className="bg-background">
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-muted-foreground">Active Forms</p>
                <h3 className="text-2xl font-bold mt-1 text-foreground">{activeFormsCount}</h3>
              </div>
              <Activity className="h-8 w-8 text-primary" />
            </div>
          </CardContent>
        </Card>
        
        <Card className="bg-background">
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-muted-foreground">Successful Checks</p>
                <div className="flex items-baseline gap-2">
                  <h3 className="text-2xl font-bold mt-1 text-foreground">{successfulChecks}</h3>
                  <span className="text-sm text-emerald-600 dark:text-emerald-400">
                    {last24hSuccess} today
                  </span>
                </div>
              </div>
              <CheckCircle2 className="h-8 w-8 text-emerald-600 dark:text-emerald-400" />
            </div>
          </CardContent>
        </Card>

        <Card className="bg-background">
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-muted-foreground">Failed Checks</p>
                <div className="flex items-baseline gap-2">
                  <h3 className="text-2xl font-bold mt-1 text-foreground">{failedChecks}</h3>
                  <span className="text-sm text-rose-600 dark:text-rose-400">
                    {last24hFailed} today
                  </span>
                </div>
              </div>
              <AlertCircle className="h-8 w-8 text-rose-600 dark:text-rose-400" />
            </div>
          </CardContent>
        </Card>

        <Card className="bg-background">
          <CardContent className="pt-6">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-muted-foreground">Avg Response Time</p>
                <div className="flex items-baseline gap-2">
                  <h3 className="text-2xl font-bold mt-1 text-foreground">
                    {avgResponseTime !== null ? `${avgResponseTime}ms` : 'N/A'}
                  </h3>
                </div>
              </div>
              <Clock className="h-8 w-8 text-primary" />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default StatsOverview;