import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";

interface PricingCardProps {
  title: string;
  price: string;
  description: string;
  features: string[];
  buttonText: string;
  onClick: () => void;
  highlighted?: boolean;
}

const PricingCard = ({
  title,
  price,
  description,
  features,
  buttonText,
  onClick,
  highlighted = false,
}: PricingCardProps) => {
  return (
    <div className={`p-8 rounded-lg border ${highlighted ? 'border-blue-500 shadow-lg' : ''} bg-card`}>
      <h3 className="text-2xl font-bold mb-2">{title}</h3>
      <div className="mb-4">
        <span className="text-4xl font-bold">{price}</span>
        {price !== "Free" && <span className="text-muted-foreground">/month</span>}
      </div>
      <p className="text-muted-foreground mb-6">{description}</p>
      <ul className="space-y-3 mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2">
            <Check className="w-5 h-5 text-blue-500" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <Button
        onClick={onClick}
        className="w-full"
        variant={highlighted ? "default" : "outline"}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export const Pricing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleNavigation = () => {
    if (user) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <section className="container mx-auto px-4 py-20 bg-muted/20">
      <h2 className="text-3xl font-bold text-center mb-4">Simple, Transparent Pricing</h2>
      <p className="text-center text-muted-foreground mb-12 max-w-2xl mx-auto">
        Choose the plan that best fits your needs. All plans include our core features.
      </p>
      <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
        <PricingCard
          title="Starter"
          price="Free"
          description="Perfect for small projects"
          features={[
            "3 Forms",
            "24/7 Monitoring",
            "Email Notifications",
            "Basic Analytics"
          ]}
          buttonText="Get Started"
          onClick={handleNavigation}
        />
        <PricingCard
          title="Pro"
          price="$29"
          description="For growing businesses"
          features={[
            "50 Forms",
            "Advanced Analytics",
            "Priority Support",
            "Custom Test Intervals"
          ]}
          buttonText="Start Free Trial"
          onClick={handleNavigation}
          highlighted
        />
        <PricingCard
          title="Enterprise"
          price="$99"
          description="For large organizations"
          features={[
            "Unlimited Forms",
            "Custom Integration",
            "24/7 Premium Support",
            "SLA Guarantee"
          ]}
          buttonText="Contact Sales"
          onClick={handleNavigation}
        />
      </div>
    </section>
  );
};