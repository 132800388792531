import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { TestResult } from '@/types/testResults';
import { format } from 'date-fns';
import { Camera, CheckCircle2, XCircle, AlertCircle, Clock } from 'lucide-react';

interface TestResultDetailsProps {
  result: TestResult | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onViewScreenshots?: () => void;
  onViewResponse?: () => void;
}

export const TestResultDetails: React.FC<TestResultDetailsProps> = ({
  result,
  open,
  onOpenChange,
  onViewScreenshots,
  onViewResponse
}) => {
  if (!result) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl max-h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <span>Test Result Details</span>
              <Badge 
                variant={result.status === 'success' ? 'success' : 
                        result.status === 'error' ? 'warning' : 'destructive'}
                className="h-6"
              >
                {result.status}
              </Badge>
              <span className="text-sm text-muted-foreground">
                {format(new Date(result.created_at), 'PPpp')}
              </span>
            </div>
          </DialogTitle>
        </DialogHeader>

        <Tabs defaultValue="overview" className="flex-1 min-h-0">
          <TabsList>
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="metadata">Metadata</TabsTrigger>
            {result.error_message && <TabsTrigger value="error">Error</TabsTrigger>}
          </TabsList>

          <TabsContent value="overview" className="flex-1 min-h-0">
            <ScrollArea className="h-full">
              <div className="space-y-6 p-4">
                {/* Status Section */}
                <div className="space-y-2">
                  <h3 className="text-sm font-medium">Status</h3>
                  <div className="flex items-center gap-2">
                    {result.status === 'success' ? (
                      <CheckCircle2 className="h-5 w-5 text-success" />
                    ) : result.status === 'error' ? (
                      <AlertCircle className="h-5 w-5 text-warning" />
                    ) : (
                      <XCircle className="h-5 w-5 text-destructive" />
                    )}
                    <span className="text-sm">Test {result.status}</span>
                  </div>
                </div>

                {/* Duration Section */}
                {result.test_duration && (
                  <div className="space-y-2">
                    <h3 className="text-sm font-medium">Duration</h3>
                    <div className="flex items-center gap-2">
                      <Clock className="h-5 w-5 text-muted-foreground" />
                      <Badge variant="outline" className="h-6">
                        {result.test_duration}ms
                      </Badge>
                      <span className={
                        result.test_duration < 1000 ? 'text-success text-xs' :
                        result.test_duration < 3000 ? 'text-warning text-xs' :
                        'text-destructive text-xs'
                      }>
                        {result.test_duration < 1000 ? 'Fast' :
                         result.test_duration < 3000 ? 'Medium' :
                         'Slow'}
                      </span>
                    </div>
                  </div>
                )}

                {/* Screenshots Section */}
                {result.screenshots && result.screenshots.length > 0 && (
                  <div className="space-y-2">
                    <h3 className="text-sm font-medium">Screenshots</h3>
                    <Button 
                      variant="outline" 
                      size="sm"
                      onClick={onViewScreenshots}
                    >
                      <Camera className="h-4 w-4 mr-2" />
                      View {result.screenshots.length} {result.screenshots.length === 1 ? 'screenshot' : 'screenshots'}
                    </Button>
                  </div>
                )}

                {/* Response Section */}
                {result.response_body && (
                  <div className="space-y-2">
                    <h3 className="text-sm font-medium">Response</h3>
                    <div className="flex items-center gap-2">
                      <Badge 
                        variant={result.response_code && result.response_code < 400 ? 'success' : 'destructive'}
                        className="h-6"
                      >
                        {result.response_code || 'N/A'}
                      </Badge>
                      {result.metadata?.response_headers?.['content-type'] && (
                        <span className="text-xs text-muted-foreground">
                          {result.metadata.response_headers['content-type']}
                        </span>
                      )}
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={onViewResponse}
                      >
                        View Response
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </ScrollArea>
          </TabsContent>

          <TabsContent value="metadata" className="flex-1 min-h-0">
            <ScrollArea className="h-full">
              <div className="p-4">
                <pre className="text-sm whitespace-pre-wrap bg-muted p-4 rounded-md">
                  {JSON.stringify(result.metadata || {}, null, 2)}
                </pre>
              </div>
            </ScrollArea>
          </TabsContent>

          {result.error_message && (
            <TabsContent value="error" className="flex-1 min-h-0">
              <ScrollArea className="h-full">
                <div className="p-4">
                  <div className="bg-destructive/10 border border-destructive/20 rounded-md p-4">
                    <pre className="text-sm font-mono text-destructive whitespace-pre-wrap">
                      {result.error_message}
                    </pre>
                  </div>
                </div>
              </ScrollArea>
            </TabsContent>
          )}
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};
