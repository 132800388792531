import { Globe, Shield, Rocket, Check } from "lucide-react";

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureCard = ({ icon, title, description }: FeatureCardProps) => {
  return (
    <div className="p-6 rounded-lg border bg-card text-card-foreground">
      <div className="mb-4">{icon}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-muted-foreground">{description}</p>
    </div>
  );
};

export const Features = () => {
  return (
    <section className="container mx-auto px-4 py-20">
      <h2 className="text-3xl font-bold text-center mb-12">Key Features</h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        <FeatureCard
          icon={<Globe className="w-10 h-10 text-blue-500" />}
          title="Form Monitoring"
          description="Test both POST and GET forms automatically at custom intervals"
        />
        <FeatureCard
          icon={<Shield className="w-10 h-10 text-blue-500" />}
          title="Advanced Testing"
          description="Support for dynamic forms and CAPTCHA detection"
        />
        <FeatureCard
          icon={<Rocket className="w-10 h-10 text-blue-500" />}
          title="Real-time Dashboard"
          description="Monitor form status and view detailed test logs"
        />
        <FeatureCard
          icon={<Check className="w-10 h-10 text-blue-500" />}
          title="Instant Alerts"
          description="Receive notifications when forms fail or succeed"
        />
      </div>
    </section>
  );
};