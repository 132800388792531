import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { formsService } from "@/services/forms/index";
import { 
  CheckCircle2, XCircle, AlertCircle, Clock, RotateCw, ArrowRight, ExternalLink,
  ChevronDown, ChevronsUpDown, Search, Filter, SlidersHorizontal, Camera
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useToast } from '@/components/ui/use-toast';
import { TestStatus, TestResult, Screenshot } from "@/types/testResults";
import { createRoot } from 'react-dom/client';
import Image from "next/image";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuLabel,
} from "@/components/ui/dropdown-menu";
import { Input } from '@/components/ui/input';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  SortingState,
  ColumnFiltersState,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { useState } from 'react';
import { cn } from "@/lib/utils";
import { TestResultScreenshots } from "@/components/TestResultScreenshots";
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { Skeleton } from '@/components/ui/skeleton';
import { ResponseViewerModal } from '@/components/ResponseViewerModal';
import { TestResultDetails } from '@/components/TestResultDetails';
import { Link, useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { useTheme } from 'next-themes';
import { JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { Search as SearchIcon, Copy, Download, Check } from 'lucide-react';

// Custom dark theme styles
const customDarkStyles = {
  container: 'json-container dark',
  basicChildStyle: 'json-basic-child dark',
  parentChildStyle: { color: '#E4E4E7' }, // Zinc-200
  label: { color: '#94A3B8' }, // Slate-400
  nullValue: { color: '#9CA3AF' }, // Gray-400
  undefinedValue: { color: '#9CA3AF' }, // Gray-400
  numberValue: { color: '#93C5FD' }, // Blue-300
  stringValue: { color: '#86EFAC' }, // Green-300
  booleanValue: { color: '#F472B6' }, // Pink-400
  otherValue: { color: '#E4E4E7' }, // Zinc-200
  punctuation: { color: '#94A3B8' }, // Slate-400
  errorStyle: { 
    backgroundColor: '#991B1B', // Red-900
    color: '#FECACA', // Red-200
    padding: '2px 4px',
    borderRadius: '4px',
  },
};

// Custom light theme styles with error highlighting
const customLightStyles = {
  container: 'json-container light',
  basicChildStyle: 'json-basic-child light',
  parentChildStyle: { color: '#18181B' }, // Zinc-900
  label: { color: '#475569' }, // Slate-600
  nullValue: { color: '#6B7280' }, // Gray-500
  undefinedValue: { color: '#6B7280' }, // Gray-500
  numberValue: { color: '#2563EB' }, // Blue-600
  stringValue: { color: '#059669' }, // Emerald-600
  booleanValue: { color: '#DB2777' }, // Pink-600
  otherValue: { color: '#18181B' }, // Zinc-900
  punctuation: { color: '#475569' }, // Slate-600
  errorStyle: { 
    backgroundColor: '#FEE2E2', // Red-100
    color: '#DC2626', // Red-600
    padding: '2px 4px',
    borderRadius: '4px',
  },
};

// Add styles to highlight error messages
const highlightErrors = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map(item => highlightErrors(item));
  }
  
  if (typeof data === 'object' && data !== null) {
    const result: Record<string, any> = {};
    for (const [key, value] of Object.entries(data)) {
      if (
        (key === 'errors' || key === 'error' || key === 'message') && 
        typeof value === 'string'
      ) {
        result[key] = { 
          __isError: true,
          value: value 
        };
      } else {
        result[key] = highlightErrors(value);
      }
    }
    return result;
  }
  
  return data;
};

interface TestResultsTableProps {
  formId: string;
  notifyOnSuccess?: boolean;
}

interface TestResultRow {
  id: string;
  status: string;
  created_at: string;
  metadata: Record<string, any>;
  errors?: Array<{
    type: string;
    message: string;
  }>;
}

const LoadingState = () => (
  <div className="space-y-4">
    <div className="flex items-center justify-between">
      <Skeleton className="h-8 w-[200px]" />
      <Skeleton className="h-8 w-[100px]" />
    </div>
    <div className="space-y-2">
      {Array.from({ length: 5 }).map((_, i) => (
        <div key={i} className="flex gap-4">
          <Skeleton className="h-12 w-full" />
        </div>
      ))}
    </div>
  </div>
);

const TestResultsTable = ({ formId }: TestResultsTableProps) => {
  const [selectedScreenshots, setSelectedScreenshots] = useState<Screenshot[] | null>(null);
  const [selectedResponse, setSelectedResponse] = useState<{
    body?: string;
    code?: number;
    contentType?: string;
  } | null>(null);
  const [selectedResult, setSelectedResult] = useState<TestResult | null>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data: results = [], isLoading, error, refetch } = useQuery({
    queryKey: ["form-results", formId],
    queryFn: () => formsService.getFormResults(formId),
    onError: (error) => {
      console.error("Error fetching test results:", error);
    },
    retry: 3,
    staleTime: 5000, // Reduce stale time to 5 seconds for more frequent updates
    refetchInterval: 10000, // Refetch every 10 seconds
    refetchIntervalInBackground: true,
    onSuccess: (data) => {
      console.log('Test results loaded:', {
        count: data.length,
        withScreenshots: data.filter(r => r.screenshots?.length > 0).length,
        screenshotSummary: data.map(r => ({
          resultId: r.id,
          screenshotCount: r.screenshots?.length || 0,
          status: r.status // Log status for debugging
        }))
      });
    }
  });

  const { toast } = useToast();

  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return (
      <Dialog open={true}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Error loading test results</DialogTitle>
            <DialogDescription>
              <div className="space-y-2">
                <p>{error instanceof Error ? error.message : 'An unexpected error occurred'}</p>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => refetch()}
                >
                  Try again
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    );
  }

  const handleRetest = async () => {
    try {
      toast({
        title: "Testing form...",
        description: "Please wait for the test to complete",
        variant: "default",
        duration: 2000,
      });
      await formsService.checkForm(formId);
      toast({
        title: "Form test initiated",
        description: "Test results will be updated shortly",
        variant: "default",
        duration: 2000,
      });
    } catch (error) {
      console.error("Form test error:", error);
      toast({
        title: "Error",
        description: "Failed to initiate form test",
        variant: "destructive",
        duration: 3000,
      });
    }
  };

  const getStatusIcon = (status: TestStatus) => {
    switch (status) {
      case "success":
        return <CheckCircle2 className="h-5 w-5 text-success" />;
      case "error":
        return <AlertCircle className="h-5 w-5 text-warning" />;
      case "failure":
        return <XCircle className="h-5 w-5 text-destructive" />;
      default:
        return <AlertCircle className="h-5 w-5 text-muted-foreground" />;
    }
  };

  const columns: ColumnDef<TestResult>[] = [
    {
      accessorKey: "status",
      header: ({ column }) => (
        <div className="flex items-center gap-2">
          <span>Status</span>
          <Button 
            variant="ghost" 
            size="icon"
            className="h-6 w-6 p-0 hover:bg-muted text-foreground"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <ChevronsUpDown className="h-4 w-4" />
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        const status = row.getValue("status") as TestStatus;
        return (
          <div className="flex items-center gap-2">
            {status === "success" ? (
              <CheckCircle2 className="h-4 w-4 text-success" />
            ) : status === "error" ? (
              <AlertCircle className="h-4 w-4 text-warning" />
            ) : (
              <XCircle className="h-4 w-4 text-destructive" />
            )}
            <span className={cn(
              "capitalize",
              status === "success" && "text-success",
              status === "error" && "text-warning",
              status === "failure" && "text-destructive"
            )}>
              {status}
            </span>
          </div>
        );
      },
      filterFn: (row, id, value) => {
        return value.length === 0 ? true : value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "response_body",
      header: ({ column }) => (
        <div className="flex items-center gap-2">
          <span>Response</span>
          <Button 
            variant="ghost" 
            size="icon"
            className="h-6 w-6 p-0 hover:bg-muted text-foreground"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <ChevronsUpDown className="h-4 w-4" />
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        const result = row.original;
        const contentType = result.metadata?.response_headers?.['content-type'];
        
        return (
          <div className="flex items-center gap-2">
            <Badge 
              variant={result.response_code && result.response_code < 400 ? 'success' : 'destructive'}
              className="h-6"
            >
              {result.response_code || 'N/A'}
            </Badge>
            {contentType && (
              <span className="text-xs text-muted-foreground">
                {contentType.split(';')[0]}
              </span>
            )}
            {result.response_body && (
              <Button
                variant="outline"
                size="sm"
                onClick={() => setSelectedResponse({
                  body: result.response_body,
                  code: result.response_code,
                  contentType: contentType
                })}
              >
                <ExternalLink className="h-4 w-4 mr-2" />
                View
              </Button>
            )}
          </div>
        );
      }
    },
    {
      accessorKey: "test_duration",
      header: ({ column }) => (
        <div className="flex items-center gap-2">
          <span>Duration</span>
          <Button 
            variant="ghost" 
            size="icon"
            className="h-6 w-6 p-0 hover:bg-muted text-foreground"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <ChevronsUpDown className="h-4 w-4" />
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        const duration = row.getValue("test_duration") as number;
        if (!duration) return <span className="text-muted-foreground text-sm">N/A</span>;
        
        return (
          <div className="flex items-center gap-2">
            <Badge variant="outline" className="h-6">
              {duration}ms
            </Badge>
            <span className={
              duration < 1000 ? 'text-success text-xs' :
              duration < 3000 ? 'text-warning text-xs' :
              'text-destructive text-xs'
            }>
              {duration < 1000 ? 'Fast' :
               duration < 3000 ? 'Medium' :
               'Slow'}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <div className="flex items-center gap-2">
          <span>Timestamp</span>
          <Button 
            variant="ghost" 
            size="icon"
            className="h-6 w-6 p-0 hover:bg-muted text-foreground"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <ChevronsUpDown className="h-4 w-4" />
          </Button>
        </div>
      ),
      cell: ({ row }) => {
        const timestamp = row.getValue("created_at") as string;
        if (!timestamp) return <span className="text-muted-foreground text-sm">N/A</span>;
        
        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="text-left">
                <div className="flex flex-col">
                  <span className="text-sm">{format(new Date(timestamp), 'PP')}</span>
                  <span className="text-xs text-muted-foreground">{format(new Date(timestamp), 'HH:mm:ss')}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {format(new Date(timestamp), 'PPpp')}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },
    {
      accessorKey: "screenshots",
      header: "Screenshots",
      cell: ({ row }) => {
        const result = row.original;
        const screenshots = Array.isArray(result.screenshots) ? result.screenshots : [];
        
        if (!screenshots.length) {
          return (
            <span className="text-muted-foreground text-sm">
              No screenshots
            </span>
          );
        }

        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  variant="outline" 
                  size="sm"
                  className="h-8"
                  onClick={() => handleViewScreenshots(screenshots)}
                >
                  <Camera className="h-4 w-4 mr-2" />
                  {screenshots.length} {screenshots.length === 1 ? 'screenshot' : 'screenshots'}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                View test screenshots
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }: { row: { original: TestResultRow } }) => {
        const [showMetadata, setShowMetadata] = useState(false);
        const [searchTerm, setSearchTerm] = useState('');
        const [copied, setCopied] = useState(false);
        const result = row.original;
        const navigate = useNavigate();
        const { theme } = useTheme();
        const { toast } = useToast();

        const handleViewError = () => {
          setShowMetadata(true);
        };

        const handleViewReport = () => {
          navigate(`/test-reports/${result.id}`);
        };

        const handleCopy = async () => {
          try {
            await navigator.clipboard.writeText(JSON.stringify(result, null, 2));
            setCopied(true);
            toast({
              title: "Success",
              description: "JSON data copied to clipboard",
              variant: "default",
              duration: 2000,
            });
            setTimeout(() => setCopied(false), 2000);
          } catch (err) {
            toast({
              title: "Error",
              description: "Failed to copy to clipboard. " + (err instanceof Error ? err.message : 'Unknown error'),
              variant: "destructive",
              duration: 3000,
            });
            console.error('Copy error:', err);
          }
        };

        const handleDownload = () => {
          try {
            const jsonString = JSON.stringify(result, null, 2);
            const blob = new Blob([jsonString], { type: 'application/json' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `test-result-${result.id}-${new Date().toISOString()}.json`;
            link.setAttribute('aria-label', 'Download test result data');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            
            toast({
              title: "Success",
              description: "JSON file has been downloaded",
              variant: "default",
              duration: 2000,
            });
          } catch (err) {
            toast({
              title: "Error",
              description: "Failed to download JSON file. " + (err instanceof Error ? err.message : 'Unknown error'),
              variant: "destructive",
              duration: 3000,
            });
            console.error('Download error:', err);
          }
        };

        const handleKeyDown = (e: React.KeyboardEvent) => {
          if (e.key === 'Escape') {
            setShowMetadata(false);
          }
        };

        const handleSearchKeyDown = (e: React.KeyboardEvent) => {
          if (e.key === 'Escape') {
            setSearchTerm('');
          }
        };

        const filterJSON = (obj: any): any => {
          if (!searchTerm) return obj;
          
          const searchRegex = new RegExp(searchTerm, 'i');
          const shouldKeep = (value: any): boolean => {
            if (typeof value === 'string') return searchRegex.test(value);
            if (typeof value === 'number') return searchRegex.test(value.toString());
            return false;
          };

          if (Array.isArray(obj)) {
            return obj
              .map(item => filterJSON(item))
              .filter(item => item !== null && Object.keys(item).length > 0);
          }

          if (typeof obj === 'object' && obj !== null) {
            const filtered: Record<string, any> = {};
            for (const [key, value] of Object.entries(obj)) {
              if (shouldKeep(key)) {
                filtered[key] = value;
                continue;
              }
              const filteredValue = filterJSON(value);
              if (filteredValue !== null && (typeof filteredValue !== 'object' || Object.keys(filteredValue).length > 0)) {
                filtered[key] = filteredValue;
              }
            }
            return Object.keys(filtered).length ? filtered : null;
          }

          return shouldKeep(obj) ? obj : null;
        };

        // Process data to highlight errors
        const processedData = highlightErrors(result);
        const filteredData = searchTerm ? filterJSON(processedData) : processedData;

        // Custom renderer for error messages
        const valueRenderer = (value: any) => {
          if (value && value.__isError) {
            return {
              style: theme === 'dark' ? customDarkStyles.errorStyle : customLightStyles.errorStyle,
              value: value.value
            };
          }
          return undefined;
        };

        return (
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="sm"
              onClick={handleViewError}
              aria-label="View error details"
            >
              View Response
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleViewReport}
              aria-label="View full test report"
            >
              View Reports
            </Button>
            <Dialog 
              open={showMetadata} 
              onOpenChange={setShowMetadata}
              onKeyDown={handleKeyDown}
            >
              <DialogContent className="max-w-[90vw] w-[1200px] max-h-[90vh]">
                <DialogHeader>
                  <DialogTitle>Test Result Details</DialogTitle>
                  <DialogDescription>
                    Interactive view of test result data including metadata and error details
                  </DialogDescription>
                </DialogHeader>
                <div className="flex items-center gap-2 mt-4">
                  <div className="relative flex-1">
                    <SearchIcon className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-foreground" />
                    <Input
                      placeholder="Search in JSON..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyDown={handleSearchKeyDown}
                      className="pl-9"
                      aria-label="Search in JSON data"
                    />
                  </div>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={handleCopy}
                    title="Copy to clipboard"
                    aria-label="Copy JSON to clipboard"
                  >
                    {copied ? <Check className="h-4 w-4" aria-hidden="true" /> : <Copy className="h-4 w-4" aria-hidden="true" />}
                    <span className="sr-only">{copied ? 'Copied' : 'Copy to clipboard'}</span>
                  </Button>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={handleDownload}
                    title="Download JSON"
                    aria-label="Download JSON file"
                  >
                    <Download className="h-4 w-4" aria-hidden="true" />
                    <span className="sr-only">Download JSON</span>
                  </Button>
                </div>
                <div className="mt-4 rounded-lg border dark:border-zinc-800">
                  <div 
                    className="bg-zinc-900 rounded-lg overflow-auto max-h-[60vh] min-h-[300px]"
                    role="region"
                    aria-label="JSON viewer"
                  >
                    <JsonView 
                      data={filteredData} 
                      style={theme === 'dark' ? customDarkStyles : customLightStyles}
                      valueRenderer={valueRenderer}
                      shouldExpandNode={() => true}
                    />
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        );
      },
    },
  ];

  const handleViewScreenshots = (screenshots: Screenshot[]) => {
    console.log('Opening screenshots:', {
      count: screenshots.length,
      samples: screenshots.slice(0, 2).map(s => ({
        step: s.step,
        hasImage: !!s.image,
        imageStart: s.image?.substring(0, 30) + '...'
      }))
    });
    setSelectedScreenshots(screenshots);
  };

  const table = useReactTable({
    data: results,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    initialState: {
      pagination: {
        pageSize: rowsPerPage,
      },
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
  });

  return (
    <ErrorBoundary>
      <div className="space-y-6">
        {/* Table Controls */}
        <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between px-1">
          <div className="flex items-center gap-4">
            <div className="relative">
              <SearchIcon className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-foreground" />
              <Input
                placeholder="Search test results..."
                value={globalFilter ?? ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="pl-9 h-9 w-[250px] sm:w-[300px] text-foreground"
              />
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="h-9">
                  <SlidersHorizontal className="mr-2 h-4 w-4" />
                  View
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[150px]">
                <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
                {table.getAllColumns().map((column) => {
                  if (column.id === "actions") return null;
                  return (
                    <DropdownMenuItem
                      key={column.id}
                      className="capitalize"
                      onClick={() => column.toggleVisibility()}
                    >
                      {column.id.replace("_", " ")}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="flex items-center gap-2">
            <Button onClick={handleRetest} variant="default" size="sm" className="h-9">
              <RotateCw className="h-4 w-4 mr-2" />
              Retest Now
            </Button>
          </div>
        </div>

        {/* Table */}
        <div className="rounded-md border border-border">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-border bg-muted">
                  {table.getHeaderGroups().map((headerGroup) => (
                    headerGroup.headers.map((header) => (
                      <th 
                        key={header.id} 
                        className="px-6 py-3.5 text-left font-medium text-foreground"
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-border">
                {table.getRowModel().rows.length === 0 ? (
                  <tr>
                    <td 
                      colSpan={columns.length} 
                      className="px-6 py-8 text-center text-muted-foreground"
                    >
                      No test results found.
                    </td>
                  </tr>
                ) : (
                  table.getRowModel().rows.map((row) => (
                    <tr 
                      key={row.id} 
                      className="bg-background hover:bg-muted/50 transition-colors"
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td 
                          key={cell.id} 
                          className="px-6 py-4 text-foreground"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div className="flex items-center justify-between border-t border-border bg-muted px-6 py-4 mt-1">
          <div className="flex items-center gap-4">
            <span className="text-sm text-muted-foreground">
              {table.getFilteredRowModel().rows.length} results
            </span>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm" className="h-9">
                  {table.getState().pagination.pageSize} per page
                  <ChevronDown className="ml-2 h-4 w-4 opacity-50" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <DropdownMenuItem
                    key={pageSize}
                    onClick={() => {
                      table.setPageSize(pageSize);
                      setRowsPerPage(pageSize);
                    }}
                  >
                    {pageSize} per page
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="flex items-center gap-6">
            <div className="text-sm text-muted-foreground">
              Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                className="h-9"
              >
                Previous
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                className="h-9"
              >
                Next
              </Button>
            </div>
          </div>
        </div>

        {/* Test Result Details Modal */}
        <TestResultDetails
          open={!!selectedResult}
          onOpenChange={(open) => !open && setSelectedResult(null)}
          result={selectedResult}
          onViewScreenshots={() => {
            if (selectedResult?.screenshots) {
              setSelectedScreenshots(selectedResult.screenshots);
            }
          }}
          onViewResponse={() => {
            if (selectedResult) {
              setSelectedResponse({
                body: selectedResult.response_body,
                code: selectedResult.response_code,
                contentType: selectedResult.metadata?.response_headers?.['content-type']
              });
            }
          }}
        />

        {/* Screenshots Modal */}
        <ErrorBoundary>
          <TestResultScreenshots
            screenshots={selectedScreenshots || []}
            open={!!selectedScreenshots}
            onClose={() => setSelectedScreenshots(null)}
          />
        </ErrorBoundary>

        {/* Response Viewer Modal */}
        <ResponseViewerModal
          open={!!selectedResponse}
          onOpenChange={(open) => !open && setSelectedResponse(null)}
          responseBody={selectedResponse?.body}
          responseCode={selectedResponse?.code}
          contentType={selectedResponse?.contentType}
        />
      </div>
    </ErrorBoundary>
  );
};

export default TestResultsTable;