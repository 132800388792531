import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/lib/supabaseClient';
import { TestResult } from '@/types/testResults';

export function useTestResult(testId: string) {
  const queryClient = useQueryClient();

  // Fetch initial data
  const {
    data: testResult,
    isLoading,
    error
  } = useQuery<TestResult>({
    queryKey: ['test-result', testId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('form_test_results')
        .select('*')
        .eq('id', testId)
        .single();

      if (error) throw error;
      if (!data) throw new Error('Test result not found');

      // Parse metadata if it's stored as a string
      if (typeof data.metadata === 'string') {
        data.metadata = JSON.parse(data.metadata);
      }

      return data as TestResult;
    },
    staleTime: Infinity, // Don't refetch automatically since we're using real-time updates
  });

  // Subscribe to real-time changes
  useEffect(() => {
    const channel = supabase
      .channel(`test-result-${testId}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'form_test_results',
          filter: `id=eq.${testId}`
        },
        (payload) => {
          // Update the query cache with the new data
          const updatedData = payload.new as TestResult;
          
          // Parse metadata if it's stored as a string
          if (typeof updatedData.metadata === 'string') {
            updatedData.metadata = JSON.parse(updatedData.metadata);
          }
          
          queryClient.setQueryData(['test-result', testId], updatedData);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [testId, queryClient]);

  return { testResult, isLoading, error };
}
