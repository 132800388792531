import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { formsService } from '@/services/forms/index';

export const usePeriodicFormTesting = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    console.log("Setting up periodic form testing...");
    
    const runTests = async () => {
      try {
        const forms = await formsService.getForms();
        console.log(`Checking ${forms.length} forms for testing...`);
        
        for (const form of forms) {
          // Check if it's time to test this form
          const lastCheck = new Date(form.lastCheck).getTime();
          const now = Date.now();
          const timeSinceLastCheck = now - lastCheck;
          const testIntervalMs = form.testInterval * 60 * 1000; // Convert minutes to milliseconds

          if (timeSinceLastCheck >= testIntervalMs) {
            console.log(`Testing form: ${form.name} (${form.url})`);
            await formsService.checkForm(form.id);
          } else {
            console.log(`Skipping form ${form.name} - next test in ${Math.round((testIntervalMs - timeSinceLastCheck) / 1000 / 60)} minutes`);
          }
        }
        
        // Refresh forms data after testing
        queryClient.invalidateQueries({ queryKey: ['forms'] });
      } catch (error) {
        console.error("Error during periodic form testing:", error);
      }
    };

    // Run tests immediately
    runTests();

    // Set up periodic checking every minute to see if any forms need testing
    const interval = setInterval(runTests, 60 * 1000);

    return () => clearInterval(interval);
  }, [queryClient]);
};