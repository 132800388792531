import { MonitoredForm } from "./types";

export const mapDbRecordToForm = (record: any): MonitoredForm => ({
  id: record.id,
  name: record.name,
  url: record.url,
  method: record.method,
  status: record.status || 'pending',
  last_check: record.last_check,
  health: record.health || 100,
  notification_email: record.notification_email,
  created_at: record.created_at,
  updated_at: record.updated_at,
  test_interval: record.test_interval,
  notify_on_success: record.notify_on_success,
  form_selector: record.form_selector,
  submit_button_selector: record.submit_button_selector,
  field_mappings: record.field_mappings || {},
  success_url: record.success_url,
  user_id: record.user_id,
  last_tested: record.last_tested,
  last_test_result: record.last_test_result,
  last_test_error: record.last_test_error,
  test_count: record.test_count || 0
});