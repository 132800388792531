import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";

export const Hero = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleNavigation = () => {
    if (user) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <section className="container mx-auto px-4 py-20 text-center">
      <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
        Monitor Your Forms with Confidence
      </h1>
      <p className="text-xl text-muted-foreground mb-8 max-w-2xl mx-auto">
        Ensure your web forms are working 24/7 with automated testing, real-time monitoring, and instant notifications.
      </p>
      <div className="flex gap-4 justify-center">
        <Button size="lg" onClick={handleNavigation}>
          {user ? "Go to Dashboard" : "Start Free Trial"}
        </Button>
        <Button size="lg" variant="outline" onClick={handleNavigation}>
          {user ? "View Dashboard" : "View Demo"}
        </Button>
      </div>
    </section>
  );
};