import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { AccordionContent } from "@/components/ui/accordion";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface FormAdvancedSettingsProps {
  formSelector: string;
  setFormSelector: (value: string) => void;
  submitButtonSelector: string;
  setSubmitButtonSelector: (value: string) => void;
  fieldMappings: Record<string, string>;
  setFieldMappings: (value: Record<string, string>) => void;
  successUrl: string;
  setSuccessUrl: (value: string) => void;
  method?: string;
  setMethod: (value: string | undefined) => void;
}

export const FormAdvancedSettings = ({
  formSelector,
  setFormSelector,
  submitButtonSelector,
  setSubmitButtonSelector,
  fieldMappings,
  setFieldMappings,
  successUrl,
  setSuccessUrl,
  method,
  setMethod
}: FormAdvancedSettingsProps) => {
  const handleAddFieldMapping = () => {
    const newMappings = { ...fieldMappings };
    // Use a timestamp-based temporary key to avoid conflicts
    const tempKey = `new-${Date.now()}`;
    newMappings[tempKey] = '';
    setFieldMappings(newMappings);
  };

  const handleUpdateFieldMapping = (oldKey: string, newKey: string, value: string) => {
    const newMappings = { ...fieldMappings };
    
    // Remove the old key
    delete newMappings[oldKey];
    
    // Only add the new mapping if the key is not empty
    if (newKey.trim()) {
      newMappings[newKey] = value;
    }
    
    // Filter out any empty mappings before setting
    const filteredMappings = Object.fromEntries(
      Object.entries(newMappings).filter(([k, v]) => k.trim() !== '')
    );
    
    setFieldMappings(filteredMappings);
  };

  const handleRemoveFieldMapping = (key: string) => {
    const newMappings = { ...fieldMappings };
    delete newMappings[key];
    setFieldMappings(newMappings);
  };

  return (
    <AccordionContent className="space-y-4">
      <div className="space-y-2">
        <label htmlFor="method" className="text-sm font-medium">Method (Optional)</label>
        <Select value={method} onValueChange={setMethod}>
          <SelectTrigger>
            <SelectValue placeholder="Auto-detect (recommended)" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="AUTO">Auto-detect (recommended)</SelectItem>
            <SelectItem value="POST">POST</SelectItem>
            <SelectItem value="GET">GET</SelectItem>
          </SelectContent>
        </Select>
        <p className="text-xs text-muted-foreground">
          The form submission method will be automatically detected. Only specify if you want to override the auto-detection.
        </p>
      </div>

      <div className="space-y-2">
        <label htmlFor="successUrl" className="text-sm font-medium">Success URL</label>
        <Input
          id="successUrl"
          value={successUrl}
          onChange={(e) => setSuccessUrl(e.target.value)}
          placeholder="https://example.com/thank-you"
          type="url"
        />
        <p className="text-xs text-muted-foreground">
          The URL that users are redirected to after successful form submission. This helps verify successful submissions.
        </p>
      </div>

      <div className="space-y-2">
        <label htmlFor="formSelector" className="text-sm font-medium">Form Selector (Optional)</label>
        <Input
          id="formSelector"
          value={formSelector}
          onChange={(e) => setFormSelector(e.target.value)}
          placeholder="#contact-form"
        />
        <p className="text-xs text-muted-foreground">
          CSS selector to identify the form (e.g., #form-id or .form-class). If not provided, the system will automatically detect the form.
        </p>
      </div>

      <div className="space-y-2">
        <label htmlFor="submitButtonSelector" className="text-sm font-medium">Submit Button Selector (Optional)</label>
        <Input
          id="submitButtonSelector"
          value={submitButtonSelector}
          onChange={(e) => setSubmitButtonSelector(e.target.value)}
          placeholder="#submit-button"
        />
        <p className="text-xs text-muted-foreground">
          CSS selector for the submit button. If not provided, the system will automatically detect the submit button.
        </p>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium">Field Mappings (Optional)</label>
        <div className="space-y-2">
          {Object.entries(fieldMappings || {})
            .filter(([key]) => key.trim() !== '')
            .map(([key, value]) => (
            <div key={key} className="flex gap-2">
              <Input
                placeholder="Field name"
                value={key}
                onChange={(e) => handleUpdateFieldMapping(key, e.target.value, value)}
                className="flex-1"
              />
              <Input
                placeholder="Field value"
                value={value}
                onChange={(e) => handleUpdateFieldMapping(key, key, e.target.value)}
                className="flex-1"
              />
              <Button 
                type="button" 
                variant="outline" 
                size="icon"
                onClick={() => handleRemoveFieldMapping(key)}
                className="shrink-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
                <span className="sr-only">Remove field mapping</span>
              </Button>
            </div>
          ))}
          <Button 
            type="button" 
            variant="outline" 
            onClick={handleAddFieldMapping} 
            className="w-full"
            disabled={Object.values(fieldMappings || {}).length > 0 && Object.values(fieldMappings).every(value => !value.trim())}
          >
            Add Field Mapping
          </Button>
        </div>
        <p className="text-xs text-muted-foreground">
          Map form field names to specific test values. For example: 'form1name' → 'John Doe'
        </p>
      </div>
    </AccordionContent>
  );
};