import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Copy, ExternalLink } from 'lucide-react';
import DOMPurify from 'dompurify';

interface ResponseViewerModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  responseBody?: string;
  responseCode?: number;
  contentType?: string;
}

export const ResponseViewerModal: React.FC<ResponseViewerModalProps> = ({
  open,
  onOpenChange,
  responseBody,
  responseCode,
  contentType
}) => {
  const [copied, setCopied] = React.useState(false);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const handleCopy = async () => {
    if (responseBody) {
      await navigator.clipboard.writeText(responseBody);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  // Function to format JSON
  const formatJSON = (text: string): string => {
    try {
      return JSON.stringify(JSON.parse(text), null, 2);
    } catch {
      return text;
    }
  };

  // Function to sanitize and prepare HTML for rendering
  const prepareHtml = (html: string): string => {
    // Remove the "View Response" button and its container from the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    
    // Remove all button elements and their parent containers that match our UI
    const buttons = tempDiv.querySelectorAll('button');
    buttons.forEach(button => {
      if (button.textContent?.includes('View Response')) {
        const container = button.closest('.flex.items-center.gap-2');
        container?.remove();
      }
    });

    // Sanitize the remaining HTML
    return DOMPurify.sanitize(tempDiv.innerHTML, {
      FORCE_BODY: true,
      SANITIZE_DOM: true,
      ALLOW_ARIA_ATTR: true,
      ALLOW_DATA_ATTR: true,
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
    });
  };

  // Update iframe content when tab changes or modal opens
  React.useEffect(() => {
    if (open && iframeRef.current && responseBody) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow?.document;
      if (doc) {
        const sanitizedHtml = prepareHtml(responseBody);
        doc.open();
        doc.write(`
          <!DOCTYPE html>
          <html>
            <head>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width, initial-scale=1">
              <style>
                body { 
                  font-family: system-ui, -apple-system, sans-serif;
                  line-height: 1.5;
                  margin: 1rem;
                }
                pre { white-space: pre-wrap; }
                img { max-width: 100%; height: auto; }
              </style>
            </head>
            <body>${sanitizedHtml}</body>
          </html>
        `);
        doc.close();
      }
    }
  }, [open, responseBody]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl max-h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <span>Response Details</span>
              <div className={`px-2 py-1 rounded text-sm ${
                responseCode && responseCode < 400 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
              }`}>
                Status: {responseCode || 'Unknown'}
              </div>
              {contentType && (
                <span className="text-sm text-muted-foreground">
                  Type: {contentType}
                </span>
              )}
            </div>
            <Button
              variant="outline"
              size="sm"
              onClick={handleCopy}
              className="gap-2"
            >
              <Copy className="h-4 w-4" />
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </DialogTitle>
        </DialogHeader>

        <Tabs defaultValue="raw" className="flex-1 min-h-0">
          <TabsList>
            <TabsTrigger value="raw">Raw HTML</TabsTrigger>
            <TabsTrigger value="json">JSON</TabsTrigger>
            <TabsTrigger value="preview">Preview</TabsTrigger>
          </TabsList>

          <TabsContent value="raw" className="flex-1 min-h-0">
            <ScrollArea className="h-full border rounded-md">
              <pre className="p-4 text-sm">
                <code>{responseBody}</code>
              </pre>
            </ScrollArea>
          </TabsContent>

          <TabsContent value="json" className="flex-1 min-h-0">
            <ScrollArea className="h-full border rounded-md">
              <pre className="p-4 text-sm">
                <code>{formatJSON(responseBody || '')}</code>
              </pre>
            </ScrollArea>
          </TabsContent>

          <TabsContent value="preview" className="flex-1 min-h-0">
            <div className="border rounded-md h-full bg-white">
              <iframe
                ref={iframeRef}
                title="Response Preview"
                className="w-full h-full border-0"
                sandbox="allow-same-origin"
              />
            </div>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};
