import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { formsService } from "@/services/forms/index";
import { CheckCircle2, XCircle, ExternalLink, Pencil } from "lucide-react";
import EditFormDialog from "./EditFormDialog";
import { MonitoredForm } from "@/types/form";
import { BrowserFormTester } from './BrowserFormTester';

const FormsTable = () => {
  const [editForm, setEditForm] = useState<MonitoredForm | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);

  const { data: forms = [], isLoading } = useQuery({
    queryKey: ['forms'],
    queryFn: formsService.getForms
  });

  if (isLoading) {
    return <div className="animate-pulse">Loading forms...</div>;
  }

  // Debug logging
  console.log('Forms data:', forms.map(form => ({
    id: form.id,
    name: form.name,
    last_check: form.last_check,
    test_interval: form.test_interval,
    nextCheck: form.last_check && form.test_interval
      ? new Date(new Date(form.last_check).getTime() + form.test_interval * 60 * 1000)
      : 'Not scheduled'
  })));

  return (
    <div className="space-y-4">
      <div className="rounded-md border">
        <table className="w-full">
          <thead>
            <tr className="border-b bg-muted/50">
              <th className="p-4 text-left font-medium">Name</th>
              <th className="p-4 text-left font-medium">URL</th>
              <th className="p-4 text-left font-medium">Method</th>
              <th className="p-4 text-left font-medium">Status</th>
              <th className="p-4 text-left font-medium">Last Tested</th>
              <th className="p-4 text-left font-medium">Next Check</th>
              <th className="p-4 text-left font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {forms.map((form) => (
              <tr key={form.id} className="border-b">
                <td className="p-4">
                  <Link to={`/forms/${form.id}`} className="hover:underline">
                    {form.name}
                  </Link>
                </td>
                <td className="p-4">
                  <a
                    href={form.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-blue-500 hover:underline"
                  >
                    {form.url.length > 30 ? form.url.substring(0, 30) + "..." : form.url}
                    <ExternalLink className="h-4 w-4" />
                  </a>
                </td>
                <td className="p-4">{form.method}</td>
                <td className="p-4">
                  <div className="flex items-center gap-2">
                    {form.health >= 80 ? (
                      <CheckCircle2 className="h-5 w-5 text-success" />
                    ) : (
                      <XCircle className="h-5 w-5 text-destructive" />
                    )}
                    <span className={form.health >= 80 ? "text-success" : "text-destructive"}>
                      {form.status}
                    </span>
                  </div>
                </td>
                <td className="p-4">
                  {form.last_tested 
                    ? format(new Date(form.last_tested), 'PP HH:mm:ss')
                    : 'Never tested'}
                </td>
                <td className="p-4">
                  {form.last_tested && typeof form.test_interval === 'number'
                    ? format(
                        new Date(new Date(form.last_tested).getTime() + (form.test_interval || 30) * 60 * 1000),
                        'PP HH:mm:ss'
                      )
                    : form.test_interval 
                      ? 'Waiting for first test'
                      : 'Not scheduled'}
                </td>
                <td className="p-4">
                  <div className="flex items-center gap-2">
                    <Link to={`/forms/${form.id}`}>
                      <Button variant="outline" size="sm">
                        View Details
                      </Button>
                    </Link>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        setEditForm(form);
                        setShowEditDialog(true);
                      }}
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                    <BrowserFormTester form={form} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <EditFormDialog
        form={editForm}
        open={showEditDialog}
        onOpenChange={setShowEditDialog}
      />
    </div>
  );
};

export default FormsTable;