import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";

export const Navigation = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleNavigation = () => {
    if (user) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <nav className="container mx-auto p-4 flex justify-between items-center">
      <div className="text-2xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
        FormSure
        <span className="inline-block w-2 h-2 bg-blue-500 rounded-full ml-1 animate-pulse"></span>
      </div>
      <div className="flex gap-4">
        <Button variant="outline" onClick={handleNavigation}>
          {user ? 'Dashboard' : 'Sign In'}
        </Button>
        <Button onClick={handleNavigation}>
          {user ? 'Go to Dashboard' : 'Get Started'}
        </Button>
      </div>
    </nav>
  );
};