import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import FormsTable from "@/components/FormsTable";
import StatsOverview from "@/components/StatsOverview";
import AddFormDialog from "@/components/AddFormDialog";
import { useState } from "react";
import { DashboardLayout } from "@/components/DashboardLayout";
import { usePeriodicFormTesting } from "@/hooks/usePeriodicFormTesting";
import { TooltipProvider } from "@/components/ui/tooltip";

const Index = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  
  // Enable periodic form testing
  usePeriodicFormTesting();

  return (
    <DashboardLayout>
      <TooltipProvider>
        <div className="space-y-8">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">Form Monitor</h1>
            <Button onClick={() => setShowAddForm(true)}>
              <Plus className="h-4 w-4 mr-2" />
              Add Form
            </Button>
          </div>

          <StatsOverview />
          <FormsTable />

          <AddFormDialog 
            open={showAddForm} 
            onOpenChange={setShowAddForm}
          />
        </div>
      </TooltipProvider>
    </DashboardLayout>
  );
};

export default Index;