import { supabase } from '@/integrations/supabase/client';
import { CreateFormData, MonitoredForm } from '@/types/form';
import { mapDbRecordToForm } from './mappers';
import { TestResult } from '@/types/testResults';
import { formsMutations } from './mutations';

export const formsService = {
  async getForms(): Promise<MonitoredForm[]> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('forms')
      .select('*')
      .eq('user_id', user.id)
      .order('created_at', { ascending: false });

    if (error) {
      console.error("Error fetching forms:", error);
      throw error;
    }

    return data.map(mapDbRecordToForm);
  },

  async getForm(id: string): Promise<MonitoredForm | undefined> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('forms')
      .select('*')
      .eq('id', id)
      .eq('user_id', user.id)
      .maybeSingle();

    if (error) {
      console.error("Error fetching form:", error);
      throw error;
    }

    return data ? mapDbRecordToForm(data) : undefined;
  },

  async getAllTestResults(): Promise<TestResult[]> {
    console.log("Fetching all test results...");
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    // First get all forms for the user
    const { data: userForms, error: formsError } = await supabase
      .from('forms')
      .select('id')
      .eq('user_id', user.id);

    if (formsError) {
      console.error("Error fetching user forms:", formsError);
      throw formsError;
    }

    if (!userForms?.length) {
      console.log("No forms found for user");
      return [];
    }

    // Then get all test results for these forms
    const { data: testResults, error: resultsError } = await supabase
      .from('form_test_results')
      .select('*')
      .in('form_id', userForms.map(f => f.id))
      .order('created_at', { ascending: false });

    if (resultsError) {
      console.error("Error fetching test results:", resultsError);
      throw resultsError;
    }

    console.log("Test results:", {
      formsCount: userForms.length,
      resultsCount: testResults?.length || 0,
      resultsByForm: userForms.map(f => ({
        formId: f.id,
        resultCount: testResults?.filter(r => r.form_id === f.id).length || 0
      }))
    });

    return testResults || [];
  },

  async getFormResults(formId: string): Promise<TestResult[]> {
    console.log('Fetching form results for formId:', formId);
    
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      console.error('No authenticated user found');
      throw new Error('User not authenticated');
    }
    console.log('Authenticated user:', user.id);

    // First verify the form belongs to the user
    const { data: form, error: formError } = await supabase
      .from('forms')
      .select('id')
      .eq('id', formId)
      .eq('user_id', user.id)
      .single();

    if (formError) {
      console.error('Error fetching form:', formError);
      throw formError;
    }

    if (!form) {
      console.error('Form not found or access denied for user:', user.id, 'form:', formId);
      throw new Error('Form not found or access denied');
    }

    console.log('Form verification successful:', form.id);

    // Fetch test results with detailed logging
    console.log('Fetching test results from database...');
    const { data, error } = await supabase
      .from('form_test_results')
      .select('*')
      .eq('form_id', formId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error("Error fetching form results:", error);
      throw error;
    }

    console.log('Test results fetched:', {
      count: data?.length,
      results: data?.map(r => ({
        id: r.id,
        status: r.status,
        created_at: r.created_at
      }))
    });

    // Return the data with minimal transformation for debugging
    return data?.map(result => ({
      ...result,
      status: result.status || 'Pending',
      screenshots: Array.isArray(result.screenshots) ? result.screenshots : []
    })) || [];
  },

  async getTestResult(testId: string): Promise<TestResult | null> {
    if (!testId) {
      console.error('No test ID provided');
      throw new Error('Test ID is required');
    }

    console.log('Fetching test result:', testId);
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No authenticated user found');
        throw new Error('User not authenticated');
      }
      console.log('User authenticated:', user.id);

      // First fetch the test result
      console.log('Fetching test result from database...');
      const { data: testResult, error: testError } = await supabase
        .from('form_test_results')
        .select('*')
        .eq('id', testId)
        .single();

      if (testError) {
        console.error("Error fetching test result:", testError);
        throw testError;
      }

      if (!testResult) {
        console.log('Test result not found:', testId);
        return null;
      }
      console.log('Test result found:', testResult.id);

      // Then verify form ownership
      console.log('Verifying form ownership...');
      const { data: form, error: formError } = await supabase
        .from('forms')
        .select('id, user_id, name, url')
        .eq('id', testResult.form_id)
        .eq('user_id', user.id)
        .single();

      if (formError) {
        console.error("Error fetching form:", formError);
        throw formError;
      }

      if (!form) {
        console.error('Form not found or access denied. Form ID:', testResult.form_id, 'User ID:', user.id);
        throw new Error('Test result not found or access denied');
      }
      console.log('Form ownership verified for form:', form.id);

      // Transform and return the data
      const transformedResult = {
        ...testResult,
        status: testResult.status || 'Pending',
        screenshots: Array.isArray(testResult.screenshots) ? testResult.screenshots : [],
        metadata: testResult.metadata || {},
        form
      };
      console.log('Test result transformed successfully');

      return transformedResult;
    } catch (error) {
      console.error('Error in getTestResult:', error);
      throw error;
    }
  },

  async createForm(formData: CreateFormData): Promise<MonitoredForm> {
    // Get the current authenticated user
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    
    // Throw an error if no user is authenticated
    if (userError || !user) {
      console.error('Authentication error:', userError);
      throw new Error('Must be logged in to create a form');
    }

    // Prepare form data with user_id
    const formWithUserId = {
      ...formData,
      user_id: user.id,
      // Ensure field_mappings is a valid JSON string
      field_mappings: JSON.stringify(formData.fieldMappings || {})
    };

    // Remove undefined fields to prevent insertion errors
    Object.keys(formWithUserId).forEach(key => 
      formWithUserId[key] === undefined && delete formWithUserId[key]
    );

    // Insert form with explicit column selection
    const { data, error } = await supabase
      .from('forms')
      .insert(formWithUserId)
      .select()
      .single();

    if (error) {
      console.error('Form creation error:', error);
      throw new Error(`Failed to create form: ${error.message}`);
    }

    return mapDbRecordToForm(data);
  },

  async updateForm(id: string, formData: Partial<CreateFormData>): Promise<MonitoredForm> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    // Prepare the update data
    const dbFormData = {
      ...('name' in formData && { name: formData.name }),
      ...('url' in formData && { url: formData.url }),
      ...('method' in formData && { method: formData.method }),
      ...('notification_email' in formData && { notification_email: formData.notification_email }),
      ...('test_interval' in formData && { test_interval: Number(formData.test_interval) }),
      ...('notify_on_success' in formData && { notify_on_success: formData.notify_on_success }),
      ...('form_selector' in formData && { form_selector: formData.form_selector }),
      ...('submit_button_selector' in formData && { submit_button_selector: formData.submit_button_selector }),
      ...('success_url' in formData && { success_url: formData.success_url }),
      ...('field_mappings' in formData && { 
        field_mappings: formData.fieldMappings ? 
          Object.fromEntries(
            Object.entries(formData.fieldMappings)
              .filter(([key]) => key.trim() !== '')
          ) : {}
      }),
      updated_at: new Date().toISOString()
    };

    console.log('Updating form with data:', { id, user_id: user.id, ...dbFormData });

    const { data, error } = await supabase
      .from('forms')
      .update(dbFormData)
      .eq('id', id)
      .eq('user_id', user.id)
      .select()
      .single();

    if (error) {
      console.error("Error updating form:", error);
      throw error;
    }

    return mapDbRecordToForm(data);
  },

  async deleteForm(id: string): Promise<void> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    const { error } = await supabase
      .from('forms')
      .delete()
      .eq('id', id)
      .eq('user_id', user.id);

    if (error) {
      console.error("Error deleting form:", error);
      throw error;
    }
  },

  checkForm: formsMutations.checkForm,
};