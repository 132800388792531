import React, { useState } from 'react';
import { Button } from './ui/button';
import { MonitoredForm } from '@/types/form';
import { useToast } from './ui/use-toast';
import { Loader2 } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

interface BrowserFormTesterProps {
  form: MonitoredForm;
}

export function BrowserFormTester({ form }: BrowserFormTesterProps) {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  
  const handleTestInBrowser = async () => {
    setIsLoading(true);
    try {
      // Ask for auto-fill preference first
      const autoFillForm = confirm('Would you like to auto-fill the form with test data?');
      
      if (!autoFillForm) {
        // If no auto-fill, just open the form
        window.open(form.url, '_blank');
        setIsLoading(false);
        return;
      }

      // Create the form filling script as a bookmarklet-style self-executing function
      const fillScript = encodeURIComponent(`
        (function() {
          // Suppress CORS errors in console
          const originalError = console.error;
          console.error = function(...args) {
            if (args[0]?.includes?.('CORS') || args[0]?.includes?.('Access-Control')) {
              return; // Suppress CORS errors
            }
            originalError.apply(console, args);
          };

          // Configuration
          const config = {
            formSelector: ${JSON.stringify(form.form_selector || 'form')},
            fieldMappings: ${JSON.stringify(form.field_mappings || {})},
            submitSelector: ${JSON.stringify(form.submit_button_selector)}
          };

          // Logging function
          function log(msg, type = 'info') {
            const styles = {
              info: 'color: #0066cc',
              success: 'color: #00cc66',
              error: 'color: #cc0000'
            };
            console.log('%c[FormFiller] ' + msg, styles[type]);
          }

          // Find element using multiple strategies
          function findElement(selector) {
            try {
              // Try direct selector
              let element = document.querySelector(selector);
              if (element) return element;

              // Try without form context
              if (selector.includes(' ')) {
                element = document.querySelector(selector.split(' ').pop());
                if (element) return element;
              }

              // Try by name or id
              element = document.querySelector(\`[name="\${selector}"]\`) || 
                       document.querySelector(\`#\${selector}\`);
              if (element) return element;

              // Try partial match
              const allInputs = document.querySelectorAll('input, select, textarea');
              for (const input of allInputs) {
                if (input.id?.includes(selector) || 
                    input.name?.includes(selector) || 
                    input.className?.includes(selector)) {
                  return input;
                }
              }

              return null;
            } catch (e) {
              return null;
            }
          }

          // Wait for an element with multiple find strategies
          function waitForElement(selector, maxAttempts = 50) {
            return new Promise((resolve) => {
              let attempts = 0;
              const check = () => {
                attempts++;
                const element = findElement(selector);
                if (element) {
                  log('Found element: ' + selector, 'success');
                  resolve(element);
                } else if (attempts < maxAttempts) {
                  setTimeout(check, 100);
                } else {
                  log('Element not found: ' + selector, 'error');
                  resolve(null);
                }
              };
              check();
            });
          }

          // Fill a single field
          async function fillField(field, value) {
            try {
              log('Filling field: ' + (field.name || field.id || 'unnamed'));
              
              // Store original properties
              const originalValue = field.value;
              const originalReadOnly = field.readOnly;
              const originalDisabled = field.disabled;

              // Make field editable
              field.readOnly = false;
              field.disabled = false;

              if (field.type === 'checkbox' || field.type === 'radio') {
                field.checked = value === 'true';
              } else {
                field.value = value;
              }

              // Try multiple event dispatching methods
              const events = ['focus', 'input', 'change', 'blur'];
              
              events.forEach(eventType => {
                try {
                  // Try modern event
                  const event = new Event(eventType, { bubbles: true });
                  field.dispatchEvent(event);
                } catch (e) {
                  try {
                    // Try legacy event
                    const event = document.createEvent('HTMLEvents');
                    event.initEvent(eventType, true, false);
                    field.dispatchEvent(event);
                  } catch (e2) {
                    // Ignore event errors
                  }
                }
              });

              // Verify the value was set
              if (field.type !== 'checkbox' && field.type !== 'radio' && field.value !== value) {
                // Try direct property assignment as fallback
                Object.getOwnPropertyDescriptor(Object.getPrototypeOf(field), 'value')?.set?.call(field, value);
                field.setAttribute('value', value);
              }

              // Restore original properties
              field.readOnly = originalReadOnly;
              field.disabled = originalDisabled;

              log('Successfully filled field: ' + (field.name || field.id), 'success');
              return true;
            } catch (error) {
              log('Error filling field: ' + error.message, 'error');
              return false;
            }
          }

          // Main form filling function
          async function fillForm() {
            try {
              log('Starting form fill process');
              let filledCount = 0;

              // Try to fill fields even if form is not found
              for (const [selector, value] of Object.entries(config.fieldMappings)) {
                const field = await waitForElement(selector);
                if (field && await fillField(field, value)) {
                  filledCount++;
                  // Small delay between fields
                  await new Promise(r => setTimeout(r, 100));
                }
              }

              log('Filled ' + filledCount + ' fields', filledCount > 0 ? 'success' : 'error');

              if (filledCount > 0 && config.submitSelector) {
                const submitButton = await waitForElement(config.submitSelector);
                if (submitButton && confirm('Form is filled. Would you like to submit it?')) {
                  submitButton.click();
                }
              }
            } catch (error) {
              log('Error filling form: ' + error.message, 'error');
            }
          }

          // Start the form filling process
          setTimeout(fillForm, 1500);
        })()
      `);

      // Open the form in a new window
      const formWindow = window.open(
        form.url,
        '_blank'
      );

      if (!formWindow) {
        toast({
          title: "Error",
          description: "Could not open form. Please check if pop-ups are allowed.",
          variant: "destructive"
        });
        setIsLoading(false);
        return;
      }

      // Inject our script
      formWindow.location.href = 'javascript:' + decodeURIComponent(fillScript);
            
      toast({
        title: "Form Opening",
        description: "The form will be filled automatically. Please check the browser console for details.",
      });

      // Clean up
      setTimeout(() => {
        setIsLoading(false);
      }, 30000);

    } catch (error) {
      console.error('Error handling form:', error);
      toast({
        title: "Error",
        description: "Could not fill form automatically. Please fill it manually.",
        variant: "destructive"
      });
      setIsLoading(false);
    }
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button 
            variant="outline"
            size="sm"
            onClick={handleTestInBrowser}
            className="ml-2"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Opening...
              </>
            ) : (
              'Test in Browser'
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Open and test the form in a new browser tab with auto-fill support</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
