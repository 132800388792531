import { supabase } from "@/integrations/supabase/client";
import { mapDbRecordToForm } from "./mappers";
import { MonitoredForm, TestResult } from "./types";

const FORM_TESTER_API = 'http://localhost:3001';

export const formsQueries = {
  getForms: async (): Promise<MonitoredForm[]> => {
    console.log("Fetching forms from database...");
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('forms')
      .select('*')
      .eq('user_id', user.id)
      .order('created_at', { ascending: false });

    if (error) {
      console.error("Error fetching forms:", error);
      throw error;
    }

    return data.map(mapDbRecordToForm);
  },

  getForm: async (id: string): Promise<MonitoredForm | undefined> => {
    console.log("Fetching form from database:", id);
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('forms')
      .select('*')
      .eq('id', id)
      .eq('user_id', user.id)
      .maybeSingle();

    if (error) {
      console.error("Error fetching form:", error);
      throw error;
    }

    return data ? mapDbRecordToForm(data) : undefined;
  },

  getFormResults: async (formId: string): Promise<TestResult[]> => {
    try {
      const { data, error } = await supabase
        .from('form_test_results')
        .select(`
          id,
          form_id,
          status,
          response_code,
          response_body,
          test_duration,
          error_message,
          screenshots:screenshots(step, image, timestamp),
          created_at,
          updated_at,
          metadata
        `)
        .eq('form_id', formId)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Transform the data to match the TestResult type
      const results = (data || []).map(result => ({
        ...result,
        screenshots: result.screenshots || [],
        metadata: result.metadata || {},
      }));

      return results;
    } catch (error) {
      console.error('Error fetching form results:', error);
      throw error;
    }
  },

  getAllTestResults: async (): Promise<TestResult[]> => {
    console.log("Fetching all test results...");
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    // Get all user's forms with their test results
    const { data, error } = await supabase
      .from('forms')
      .select(`
        id,
        user_id,
        form_test_results (
          id,
          form_id,
          url,
          status,
          response_code,
          test_duration,
          error_message,
          created_at,
          updated_at
        )
      `)
      .eq('user_id', user.id);

    if (error) {
      console.error("Error fetching user forms:", error);
      throw error;
    }

    // Flatten and validate the results
    const allResults = data?.flatMap(form => 
      (form.form_test_results || []).map(result => ({
        ...result,
        form_id: form.id,
        user_id: form.user_id
      }))
    ) || [];

    // Sort by created_at
    allResults.sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    console.log("Test results:", {
      formsCount: data?.length || 0,
      resultsCount: allResults.length,
      resultsByForm: data?.map(f => ({
        formId: f.id,
        resultCount: f.form_test_results?.length || 0
      }))
    });

    return allResults;
  },

  testForm: async (url: string): Promise<TestResult> => {
    console.log("Testing form:", url);
    const response = await fetch(`${FORM_TESTER_API}/test-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url }),
    });

    if (!response.ok) {
      const error = await response.json();
      console.error("Error testing form:", error);
      throw new Error(error.message || 'Failed to test form');
    }

    return response.json();
  },

  getTestResult: async (testId: string) => {
    const { data, error } = await supabase
      .from('form_test_results')
      .select(`
        *,
        form:forms (
          id,
          name,
          url
        )
      `)
      .eq('id', testId)
      .single();

    if (error) {
      console.error('Error fetching test result:', error);
      throw error;
    }

    return data;
  },

  getLatestTestResults: async (formId: string, limit = 10) => {
    const { data, error } = await supabase
      .from('form_test_results')
      .select(`
        *,
        form:forms (
          id,
          name,
          url
        )
      `)
      .eq('form_id', formId)
      .order('created_at', { ascending: false })
      .limit(limit);

    if (error) {
      console.error('Error fetching test results:', error);
      throw error;
    }

    return data;
  },

  getTestResults: async (formId: string, { page = 1, pageSize = 10 } = {}) => {
    // First get total count
    const { count, error: countError } = await supabase
      .from('form_test_results')
      .select('*', { count: 'exact', head: true })
      .eq('form_id', formId);

    if (countError) {
      console.error('Error getting test results count:', countError);
      throw countError;
    }

    // Then get paginated results
    const { data, error } = await supabase
      .from('form_test_results')
      .select(`
        *,
        form:forms (
          id,
          name,
          url
        )
      `)
      .eq('form_id', formId)
      .order('created_at', { ascending: false })
      .range((page - 1) * pageSize, page * pageSize - 1);

    if (error) {
      console.error('Error fetching test results:', error);
      throw error;
    }

    return {
      results: data,
      pagination: {
        total: count,
        page,
        pageSize,
        totalPages: Math.ceil(count / pageSize)
      }
    };
  },
};