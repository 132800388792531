import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type Theme = "dark" | "light";

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  storageKey?: string;
};

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
  theme: "dark",
  setTheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

const DARK_MODE_ONLY_ROUTES = ["/", "/login"];

export function ThemeProvider({
  children,
  defaultTheme = "dark",
  storageKey = "form-monitor-theme",
  ...props
}: ThemeProviderProps) {
  const location = useLocation();
  const [theme, setTheme] = useState<Theme>(
    () => (localStorage.getItem(storageKey) as Theme) || defaultTheme
  );

  useEffect(() => {
    const root = window.document.documentElement;
    const isDarkModeOnlyRoute = DARK_MODE_ONLY_ROUTES.includes(location.pathname);
    
    root.classList.remove("light", "dark");
    root.classList.add(isDarkModeOnlyRoute ? "dark" : theme);
    
    if (!isDarkModeOnlyRoute) {
      localStorage.setItem(storageKey, theme);
    }
    
    console.log(
      "Theme updated:",
      isDarkModeOnlyRoute ? "dark (forced)" : theme,
      "Route:",
      location.pathname
    );
  }, [theme, storageKey, location]);

  const value = {
    theme: DARK_MODE_ONLY_ROUTES.includes(location.pathname) ? "dark" : theme,
    setTheme: (theme: Theme) => {
      if (!DARK_MODE_ONLY_ROUTES.includes(location.pathname)) {
        console.log("Setting theme to:", theme);
        setTheme(theme);
      }
    },
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined)
    throw new Error("useTheme must be used within a ThemeProvider");

  return context;
};