import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Accordion, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { formsService } from "@/services/forms/index";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@/components/ui/use-toast";
import { FormAdvancedSettings } from "./form-dialog/FormAdvancedSettings";
import { useAuth } from "@/contexts/AuthContext";
import { useNavigate } from "react-router-dom";

interface AddFormDialogProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const AddFormDialog = ({ open, onOpenChange }: AddFormDialogProps) => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [method, setMethod] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState("");
  const [testInterval, setTestInterval] = useState("1440"); // Default to 24 hours
  const [notifyOnSuccess, setNotifyOnSuccess] = useState(false);
  const [formSelector, setFormSelector] = useState("");
  const [submitButtonSelector, setSubmitButtonSelector] = useState("");
  const [fieldMappings, setFieldMappings] = useState<Record<string, string>>({});
  const [successUrl, setSuccessUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!user) {
      toast({
        title: "Authentication Error",
        description: "You must be logged in to create a form",
        variant: "destructive",
      });
      navigate("/login");
      return;
    }

    setIsSubmitting(true);

    try {
      const cleanedFieldMappings = Object.keys(fieldMappings).length > 0 
        ? fieldMappings 
        : {};

      // Create the form data with correct field names
      const formData = {
        name,
        url,
        method: method || 'AUTO',
        notification_email: email || null,
        test_interval: parseInt(testInterval),
        notify_on_success: notifyOnSuccess,
        form_selector: formSelector || null,
        submit_button_selector: submitButtonSelector || null,
        field_mappings: cleanedFieldMappings,
        success_url: successUrl || null
      };

      console.log('Submitting form data:', formData);

      await formsService.createForm(formData);

      queryClient.invalidateQueries({ queryKey: ['forms'] });
      
      toast({
        title: "Success",
        description: "Form added successfully",
      });

      // Reset form
      setName("");
      setUrl("");
      setMethod(undefined);
      setEmail("");
      setTestInterval("1440");
      setNotifyOnSuccess(false);
      setFormSelector("");
      setSubmitButtonSelector("");
      setFieldMappings({});
      setSuccessUrl("");
      
      // Close dialog
      onOpenChange?.(false);
    } catch (error: any) {
      console.error("Error adding form:", error);
      
      const errorMessage = error instanceof Error 
        ? error.message 
        : "Failed to add form. Please try again.";

      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
      
      // If authentication error, redirect to login
      if (errorMessage.toLowerCase().includes("logged in")) {
        navigate("/login");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Add New Form</DialogTitle>
        </DialogHeader>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-medium">Form Name</label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Contact Form"
              required
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="url" className="text-sm font-medium">Form URL</label>
            <Input
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://example.com/contact"
              type="url"
              required
            />
            <p className="text-sm text-muted-foreground">
              The URL of the page containing your form. The method (GET/POST) will be automatically detected.
            </p>
          </div>

          <Accordion type="single" collapsible>
            <AccordionItem value="advanced">
              <AccordionTrigger>Advanced Settings</AccordionTrigger>
              <FormAdvancedSettings 
                formSelector={formSelector}
                setFormSelector={setFormSelector}
                submitButtonSelector={submitButtonSelector}
                setSubmitButtonSelector={setSubmitButtonSelector}
                fieldMappings={fieldMappings}
                setFieldMappings={setFieldMappings}
                successUrl={successUrl}
                setSuccessUrl={setSuccessUrl}
                method={method}
                setMethod={setMethod}
              />
            </AccordionItem>
          </Accordion>

          <div className="space-y-2">
            <label htmlFor="testInterval" className="text-sm font-medium">Test Interval (minutes)</label>
            <Input
              id="testInterval"
              value={testInterval}
              onChange={(e) => setTestInterval(e.target.value)}
              type="number"
              min="1"
              required
            />
            <p className="text-xs text-muted-foreground">
              Default: 24 hours (1440 minutes). You can adjust this to any value.
            </p>
          </div>

          <div className="space-y-2">
            <label htmlFor="email" className="text-sm font-medium">Notification Email</label>
            <Input
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="notifications@example.com"
              type="email"
            />
            <p className="text-xs text-muted-foreground">
              Email address where form test failure notifications will be sent.
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <Switch
              id="notifyOnSuccess"
              checked={notifyOnSuccess}
              onCheckedChange={setNotifyOnSuccess}
            />
            <div className="space-y-1">
              <label htmlFor="notifyOnSuccess" className="text-sm font-medium">
                Notify on successful tests
              </label>
              <p className="text-xs text-muted-foreground">
                Enable this to receive email notifications for successful tests as well as failures
              </p>
            </div>
          </div>

          <div className="flex justify-end space-x-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange?.(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Adding..." : "Add Form"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddFormDialog;