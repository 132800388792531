import { supabase } from "@/integrations/supabase/client";
import { CreateFormData, MonitoredForm } from "./types";
import { mapDbRecordToForm } from "./mappers";
import { toast } from "sonner";
import { formsQueries } from "./queries";

const FORM_TESTER_API = 'http://localhost:3001';

export const formsMutations = {
  createForm: async (data: CreateFormData): Promise<MonitoredForm> => {
    console.log("Creating form in database:", data);
    
    // Get the current user's session
    const { data: { user }, error: sessionError } = await supabase.auth.getUser();
    
    if (sessionError) {
      console.error("Authentication error:", sessionError);
      throw new Error("Authentication failed. Please try logging in again.");
    }

    if (!user) {
      console.error("No user found in session");
      throw new Error("You must be logged in to create a form");
    }

    try {
      // Create the form with exact database column names
      const formPayload = {
        name: data.name,
        url: data.url,
        method: data.method,
        notification_email: data.notification_email,
        test_interval: data.test_interval,
        notify_on_success: data.notify_on_success,
        form_selector: data.form_selector,
        submit_button_selector: data.submit_button_selector,
        field_mappings: data.field_mappings,
        success_url: data.success_url,
        user_id: user.id,
        status: 'pending',
        health: 100,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      console.log('Sending form payload to Supabase:', formPayload);

      const { data: newForm, error } = await supabase
        .from('forms')
        .insert([formPayload])
        .select('*')
        .single();

      if (error) {
        console.error("Error creating form:", error);
        if (error.code === '23502') {
          throw new Error("User authentication required. Please log in again.");
        }
        throw error;
      }

      return mapDbRecordToForm(newForm);
    } catch (error) {
      console.error("Failed to create form:", error);
      throw error;
    }
  },

  updateForm: async (id: string, data: Partial<CreateFormData>): Promise<MonitoredForm> => {
    console.log("Updating form in database:", id, data);
    
    // Prepare update data, ensuring test_interval is a number
    const updateData = {
      ...(data.name !== undefined && { name: data.name }),
      ...(data.url !== undefined && { url: data.url }),
      ...(data.method !== undefined && { method: data.method }),
      ...(data.notification_email !== undefined && { notification_email: data.notification_email }),
      ...(data.test_interval !== undefined && { test_interval: Number(data.test_interval) }),
      ...(data.notify_on_success !== undefined && { notify_on_success: data.notify_on_success }),
      ...(data.form_selector !== undefined && { form_selector: data.form_selector }),
      ...(data.submit_button_selector !== undefined && { submit_button_selector: data.submit_button_selector }),
      ...(data.field_mappings !== undefined && { field_mappings: data.field_mappings }),
      ...(data.success_url !== undefined && { success_url: data.success_url }),
      updated_at: new Date().toISOString()
    };

    console.log("Prepared update data:", updateData);

    const { data: updatedForm, error } = await supabase
      .from('forms')
      .update(updateData)
      .eq('id', id)
      .select('*')
      .single();

    if (error) {
      console.error("Error updating form:", error);
      throw error;
    }

    console.log("Form updated successfully:", updatedForm);
    return mapDbRecordToForm(updatedForm);
  },

  deleteForm: async (id: string): Promise<void> => {
    console.log("Deleting form from database:", id);
    const { error } = await supabase
      .from('forms')
      .delete()
      .eq('id', id);

    if (error) {
      console.error("Error deleting form:", error);
      throw error;
    }
  },

  testForm: async (id: string): Promise<void> => {
    console.log("Testing form:", id);
    
    const { data: form } = await supabase
      .from('forms')
      .select('*')
      .eq('id', id)
      .single();

    if (!form) {
      throw new Error('Form not found');
    }

    try {
      // Test the form using the form tester service
      const response = await fetch(`${FORM_TESTER_API}/test-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: form.url,
          form_id: form.id,
          testData: form.field_mappings || {},
          successIndicators: {
            successMessages: ["Thank you", "Success", "Submitted"],
            successSelectors: [".success-message", ".thank-you", ".success"],
            successUrls: [form.success_url || ""]
          }
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to test form');
      }

      const result = await response.json();
      console.log("Form test initiated:", result);
    } catch (error) {
      console.error("Error testing form:", error);
      throw error;
    }
  },

  checkForm: async (id: string): Promise<MonitoredForm> => {
    console.log("Checking form:", id);
    const form = await formsQueries.getForm(id);
    if (!form) {
      throw new Error("Form not found");
    }

    try {
      // Test the form using the form tester service
      const response = await fetch(`${FORM_TESTER_API}/test-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          form_id: id,
          url: form.url,
          testData: form.fieldMappings || {},
          successIndicators: {
            successMessages: ["Thank you", "Success", "Submitted"],
            successSelectors: [".success-message", ".thank-you", ".success"],
            successUrls: [form.successUrl || ""]
          }
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to test form');
      }

      const result = await response.json();
      console.log("Form test result:", result);

      // Update form status based on test result
      const { data: updatedForm, error: updateError } = await supabase
        .from('forms')
        .update({
          last_check: new Date().toISOString(),
          status: result.status === 'success' ? 'active' : result.status === 'warning' ? 'warning' : 'error',
          health: result.testResult?.success ? 'healthy' : 'failed'
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) {
        console.error("Error updating form status:", updateError);
        throw updateError;
      }

      return mapDbRecordToForm(updatedForm);
    } catch (error) {
      console.error("Error checking form:", error);
      throw error;
    }
  }
};