import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Screenshot } from '@/types/testResults';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Maximize2, X } from 'lucide-react';

interface TestResultScreenshotsProps {
  screenshots: Screenshot[];
  onClose?: () => void;
  open?: boolean;
}

const ImageWithLoading: React.FC<{
  src: string;
  alt: string;
  className?: string;
  onError?: () => void;
}> = ({ src, alt, className, onError }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!src) {
      console.error('No image source provided');
      setHasError(true);
      onError?.();
      return;
    }

    // Validate base64 image data
    try {
      //const isDataUrl = src;
      //const base64Data = isDataUrl ? src.split(',')[1] : src;
      
      // Test if it's valid base64
      // if (!base64Data) {
      //   throw new Error('No base64 data found');
      // }
      
      // Test decode a small sample
      //const sampleLength = Math.min(100, base64Data.length);
      //const sample = base64Data.substring(0, sampleLength);
      //atob(sample);
      
      // Format the URL
      //const formattedUrl = src;//isDataUrl ? src : `data:image/jpeg;base64,${src}`;
      console.log('Processing image:', {
        src,
        urlLength: src.length,
        urlStart: src.substring(0, 50) + '...'
      });
      
      setImageUrl(src);
      setHasError(false);
    } catch (error) {
      console.error('Invalid image data:', {
        error,
        srcLength: src.length,
        srcStart: src.substring(0, 50) + '...'
      });
      setHasError(true);
      onError?.();
    }
  }, [src, onError]);

  if (hasError) {
    return (
      <div className="flex items-center justify-center bg-gray-100 rounded-lg w-full h-full min-h-[200px]">
        <span className="text-sm text-gray-500">Failed to load image</span>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full min-h-[200px]">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg">
          <div className="animate-pulse w-8 h-8 bg-gray-200 rounded-full" />
        </div>
      )}
      {imageUrl && (
        <img
          src={imageUrl}
          alt={alt}
          className={cn(
            'w-full h-full object-cover rounded-lg transition-opacity duration-300',
            isLoading ? 'opacity-0' : 'opacity-100',
            className
          )}
          onLoad={() => {
            console.log('Image loaded successfully:', alt);
            setIsLoading(false);
          }}
          onError={(e) => {
            console.error('Image load error:', {
              error: e,
              src: imageUrl.substring(0, 50) + '...',
              alt
            });
            setHasError(true);
            onError?.();
          }}
        />
      )}
    </div>
  );
};

export const TestResultScreenshots: React.FC<TestResultScreenshotsProps> = ({ 
  screenshots,
  open = false,
  onClose 
}) => {
  const [selectedImage, setSelectedImage] = useState<Screenshot | null>(null);
  const [failedScreenshots, setFailedScreenshots] = useState<Set<number>>(new Set());

  useEffect(() => {
    if (!open) {
      setSelectedImage(null);
      setFailedScreenshots(new Set());
    }
  }, [open]);

  const formatStep = (step: string) => {
    return step
      ?.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ') || 'Unknown Step';
  };

  const formatTimestamp = (timestamp: string) => {
    try {
      return new Date(timestamp).toLocaleString();
    } catch (error) {
      return 'Invalid date';
    }
  };

  const handleImageError = (index: number) => {
    setFailedScreenshots(prev => new Set([...prev, index]));
  };

  const validScreenshots = screenshots.filter((_, index) => !failedScreenshots.has(index));

  console.log(validScreenshots);

  if (!Array.isArray(screenshots) || validScreenshots.length === 0) {
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent>
          <DialogTitle>Test Screenshots</DialogTitle>
          <div className="p-4 text-gray-500 text-center">
            No valid screenshots available for this test run
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="max-w-4xl w-full max-h-[90vh] overflow-auto">
          <div className="flex items-center justify-between mb-4">
            <DialogTitle>Test Screenshots</DialogTitle>
            <Button variant="ghost" size="icon" onClick={onClose}>
              <X className="h-4 w-4" />
            </Button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
            {validScreenshots.map((screenshot, index) => (
              <div
                key={`${screenshot.stage}-${index}`}
                className="group relative space-y-2 cursor-pointer rounded-lg overflow-hidden border border-gray-200"
                onClick={() => setSelectedImage(screenshot)}
              >
                <ImageWithLoading
                  src={screenshot.url}
                  alt={formatStep(screenshot.stage)}
                  className="aspect-video object-cover"
                  onError={() => handleImageError(index)}
                />
                <div className="absolute inset-0 bg-black/0 group-hover:bg-black/40 transition-colors duration-200" />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  <Maximize2 className="w-6 h-6 text-white" />
                </div>
                <div className="p-3 bg-white">
                  <p className="font-medium text-sm">{formatStep(screenshot.stage)}</p>
                  <p className="text-xs text-gray-500">
                    {formatTimestamp(screenshot.timestamp)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>

      {selectedImage && (
        <Dialog open={!!selectedImage} onOpenChange={() => setSelectedImage(null)}>
          <DialogContent className="max-w-6xl w-full max-h-[90vh]">
            <div className="flex items-center justify-between mb-4">
              <DialogTitle>{formatStep(selectedImage.stage)}</DialogTitle>
              <Button variant="ghost" size="icon" onClick={() => setSelectedImage(null)}>
                <X className="h-4 w-4" />
              </Button>
            </div>
            <div className="relative w-full h-full max-h-[70vh] overflow-auto">
              <ImageWithLoading
                src={selectedImage.url}
                alt={formatStep(selectedImage.stage)}
                className="w-full h-full object-contain"
              />
            </div>
            <p className="text-sm text-gray-500 mt-2">
              {formatTimestamp(selectedImage.timestamp)}
            </p>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
