import { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Accordion, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { formsService } from "@/services/forms/index";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@/hooks/use-toast";
import { MonitoredForm } from "@/types/form";
import { FormAdvancedSettings } from "./form-dialog/FormAdvancedSettings";

interface EditFormDialogProps {
  form?: MonitoredForm;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const EditFormDialog = ({ form, open, onOpenChange }: EditFormDialogProps) => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [method, setMethod] = useState("POST");
  const [email, setEmail] = useState("");
  const [testInterval, setTestInterval] = useState("1440"); // Default to 24 hours
  const [notifyOnSuccess, setNotifyOnSuccess] = useState(false);
  const [formSelector, setFormSelector] = useState("");
  const [submitButtonSelector, setSubmitButtonSelector] = useState("");
  const [fieldMappings, setFieldMappings] = useState<Record<string, string>>({});
  const [successUrl, setSuccessUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const queryClient = useQueryClient();
  const { toast } = useToast();

  useEffect(() => {
    if (form) {
      setName(form.name);
      setUrl(form.url);
      setMethod(form.method);
      setEmail(form.notification_email || "");
      // Ensure test_interval is a string for the input
      setTestInterval(String(form.test_interval || 1440));
      setNotifyOnSuccess(form.notify_on_success);
      setFormSelector(form.form_selector || "");
      setSubmitButtonSelector(form.submit_button_selector || "");
      setFieldMappings(form.field_mappings || {});
      setSuccessUrl(form.success_url || "");
    }
  }, [form]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!form) return;
    
    // Validate test interval
    const parsedInterval = Number(testInterval);
    if (isNaN(parsedInterval) || parsedInterval <= 0) {
      toast({
        title: "Invalid Test Interval",
        description: "Test interval must be a positive number",
        variant: "destructive",
      });
      return;
    }
    
    setIsSubmitting(true);

    try {
      const formData = {
        name,
        url,
        method,
        notification_email: email || null,
        test_interval: parsedInterval, // Send as number
        notify_on_success: notifyOnSuccess,
        form_selector: formSelector || null,
        submit_button_selector: submitButtonSelector || null,
        field_mappings: fieldMappings,
        success_url: successUrl || null
      };

      console.log('Updating form with data:', formData);
      
      await formsService.updateForm(form.id, formData);

      queryClient.invalidateQueries({ queryKey: ['forms'] });
      queryClient.invalidateQueries({ queryKey: ['form', form.id] });
      
      toast({
        title: "Success",
        description: "Form updated successfully. The new test interval will be applied from the next test.",
      });
      
      onOpenChange?.(false);
    } catch (error) {
      console.error("Error updating form:", error);
      toast({
        title: "Error",
        description: "Failed to update form. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Edit Form</DialogTitle>
        </DialogHeader>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-medium">Form Name</label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Contact Form"
              required
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="url" className="text-sm font-medium">Form URL</label>
            <Input
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://example.com/contact"
              type="url"
              required
            />
          </div>

          <Accordion type="single" collapsible>
            <AccordionItem value="advanced">
              <AccordionTrigger>Advanced Settings</AccordionTrigger>
              <FormAdvancedSettings 
                formSelector={formSelector}
                setFormSelector={setFormSelector}
                submitButtonSelector={submitButtonSelector}
                setSubmitButtonSelector={setSubmitButtonSelector}
                fieldMappings={fieldMappings}
                setFieldMappings={setFieldMappings}
                successUrl={successUrl}
                setSuccessUrl={setSuccessUrl}
                method={method}
                setMethod={setMethod}
              />
            </AccordionItem>
          </Accordion>

          <div className="space-y-2">
            <label htmlFor="testInterval" className="text-sm font-medium">
              Test Interval (minutes)
            </label>
            <Input
              id="testInterval"
              type="number"
              min="1"
              step="1"
              value={testInterval}
              onChange={(e) => {
                const val = e.target.value;
                // Only allow positive integers
                if (/^\d+$/.test(val) || val === '') {
                  setTestInterval(val);
                }
              }}
              placeholder="Enter test interval in minutes"
              required
            />
            <p className="text-xs text-muted-foreground mt-1">
              Current interval: {testInterval} minutes
            </p>
          </div>

          <div className="space-y-2">
            <label htmlFor="email" className="text-sm font-medium">Notification Email</label>
            <Input
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="notifications@example.com"
              type="email"
            />
            <p className="text-xs text-muted-foreground">
              Email address where form test failure notifications will be sent.
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <Switch
              id="notifyOnSuccess"
              checked={notifyOnSuccess}
              onCheckedChange={setNotifyOnSuccess}
            />
            <div className="space-y-1">
              <label htmlFor="notifyOnSuccess" className="text-sm font-medium">
                Notify on successful tests
              </label>
              <p className="text-xs text-muted-foreground">
                Enable this to receive email notifications for successful tests as well as failures
              </p>
            </div>
          </div>

          <div className="flex justify-end space-x-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange?.(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Updating..." : "Update Form"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditFormDialog;
