import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  useEffect(() => {
    // On localhost, always allow access
    if (isLocalhost) {
      return;
    }

    // Only check session on non-localhost
    if (!session) {
      navigate("/login", { replace: true });
    }
  }, [session, navigate, isLocalhost]);

  // On localhost or with valid session, render children
  return <>{children}</>;
};

export default ProtectedRoute;