import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTestResult } from '@/hooks/useTestResult';
import { DashboardLayout } from '@/components/DashboardLayout';
import { TestResultReport } from '@/components/TestResultReport';
import { useToast } from '@/components/ui/use-toast';
import { Skeleton } from '@/components/ui/skeleton';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle, ArrowLeft, Globe, ArrowRight, Clock, XCircle, AlertTriangle, Shield, Lock, Eye, Type, TextCursor, HelpCircle, Smartphone, CheckCircle2, Code2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { ScrollArea } from '@/components/ui/scroll-area';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';

// Constants for status colors and icons
const STATUS_VARIANTS = {
  success: "success",
  failed: "destructive",
  pending: "secondary",
  running: "secondary"
} as const;

const STATUS_ICONS = {
  success: <CheckCircle2 className="h-4 w-4" />,
  failed: <XCircle className="h-4 w-4" />,
  pending: <Clock className="h-4 w-4" />,
  running: <AlertCircle className="h-4 w-4" />
} as const;

// Types
type TestStatus = 'success' | 'failed' | 'pending' | 'running';

interface Error {
  type: string;
  message: string;
  timestamp?: string;
}

interface Warning {
  type: string;
  message: string;
  timestamp?: string;
}

interface Performance {
  time_to_first_byte?: number;
  page_load_time?: number;
  form_submission_time?: number;
  JSHeapUsedSize?: string;
  Nodes?: number;
  ScriptDuration?: number;
}

interface NetworkAssetSizes {
  images?: number;
  scripts?: number;
  styles?: number;
  total?: number;
}

interface Network {
  request_count?: number;
  asset_sizes?: NetworkAssetSizes;
  redirect_chain?: string[];
}

interface Security {
  has_csrf_token?: boolean;
  is_secure_endpoint?: boolean;
  has_sensitive_fields?: boolean;
  vulnerabilities?: string[];
}

interface Accessibility {
  has_labels?: boolean;
  has_placeholders?: boolean;
  has_help_text?: boolean;
  is_mobile_friendly?: boolean;
  issues?: string[];
}

interface TestMetadata {
  form_count?: number;
  total_fields?: number;
  required_fields?: number;
  field_types?: Record<string, number>;
  form_method?: string;
  form_action?: string;
  form_encoding?: string;
  performance?: Performance;
  network?: Network;
  security?: Security;
  accessibility?: Accessibility;
  screenshots?: string[];
}

interface TestResult {
  id: string;
  status: TestStatus;
  created_at: string;
  test_duration: number;
  response_code?: number;
  metadata: TestMetadata;
  errors: Error[];
  warnings: Warning[];
  form?: {
    id: string;
    name: string;
    url: string;
  };
}

// Utility functions
const formatBytes = (bytes: number): string => {
  if (bytes < 1024) return `${bytes} bytes`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
  if (bytes < 1024 * 1024 * 1024) return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
};

const getStatusVariant = (status: string): keyof typeof STATUS_VARIANTS => {
  return STATUS_VARIANTS[status.toLowerCase() as keyof typeof STATUS_VARIANTS] || "secondary";
};

const getStatusIcon = (status: string) => {
  return STATUS_ICONS[status.toLowerCase() as keyof typeof STATUS_ICONS] || <AlertCircle className="h-4 w-4" />;
};

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'success':
      return 'bg-green-500/10 text-green-500';
    case 'error':
    case 'failed':
      return 'bg-red-500/10 text-red-500';
    case 'warning':
      return 'bg-yellow-500/10 text-yellow-500';
    default:
      return 'bg-blue-500/10 text-blue-500';
  }
};

const COLORS = {
  success: '#10B981',
  error: '#EF4444',
  warning: '#F59E0B',
  info: '#3B82F6'
};

const TestReports = () => {
  const { testId } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  // Validate test ID
  useEffect(() => {
    if (!testId) {
      toast({
        title: "Error",
        description: "No test ID provided",
        variant: "destructive"
      });
      navigate('/');
      return;
    }
  }, [testId, navigate, toast]);

  // Use our real-time test result hook
  const { testResult, isLoading, error } = useTestResult(testId as string);

  if (error) {
    return (
      <DashboardLayout>
        <div className="container mx-auto p-6">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error Loading Test Result</AlertTitle>
            <AlertDescription>
              {error instanceof Error ? error.message : 'Failed to load test result'}
            </AlertDescription>
          </Alert>
        </div>
      </DashboardLayout>
    );
  }

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="container mx-auto p-6 space-y-6">
          <Skeleton className="h-[200px] w-full" />
          <div className="space-y-4">
            <Skeleton className="h-10 w-[200px]" />
            <Skeleton className="h-[400px] w-full" />
          </div>
        </div>
      </DashboardLayout>
    );
  }

  if (!testResult) {
    return (
      <DashboardLayout>
        <div className="container mx-auto p-6">
          <Alert>
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Test Result Not Found</AlertTitle>
            <AlertDescription>
              The requested test result could not be found.
            </AlertDescription>
          </Alert>
        </div>
      </DashboardLayout>
    );
  }

  // Extract metadata with proper type safety
  const metadata = testResult.metadata || {};
  const performance = metadata.performance || {};
  const errors = metadata.errors || [];
  const warnings = metadata.warnings || [];

  return (
    <DashboardLayout>
      <div className="container mx-auto py-6 space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <Button 
                variant="ghost" 
                size="sm" 
                onClick={() => navigate(-1)}
                className="gap-2"
              >
                <ArrowLeft className="h-4 w-4" />
                Back
              </Button>
              <Separator orientation="vertical" className="h-6" />
              <h1 className="text-2xl font-bold">Test Result Report</h1>
            </div>
            <p className="text-muted-foreground">
              Form: {testResult.form?.name || 'Unknown Form'}
            </p>
          </div>
          <div className="flex items-center gap-4">
            <Button
              variant="outline"
              size="sm"
              className="gap-2"
              asChild
            >
              <a href={testResult.form?.url} target="_blank" rel="noopener noreferrer">
                <Globe className="h-4 w-4" />
                View Form
              </a>
            </Button>
          </div>
        </div>

        {/* Quick Stats */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-muted-foreground">Status</p>
                  <div className="flex items-center gap-2 mt-1">
                    <Badge variant={getStatusVariant(testResult.status)} className={getStatusColor(testResult.status)}>
                      <span className="flex items-center gap-1">
                        {getStatusIcon(testResult.status)}
                        {testResult.status}
                      </span>
                    </Badge>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-muted-foreground">Duration</p>
                  <div className="flex items-center gap-2 mt-1">
                    <Clock className="h-4 w-4 text-muted-foreground" />
                    <p className="text-2xl font-bold">{testResult.test_duration}ms</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-muted-foreground">Errors</p>
                  <div className="flex items-center gap-2 mt-1">
                    <XCircle className="h-4 w-4 text-red-500" />
                    <p className="text-2xl font-bold">{errors.length}</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-muted-foreground">Warnings</p>
                  <div className="flex items-center gap-2 mt-1">
                    <AlertTriangle className="h-4 w-4 text-yellow-500" />
                    <p className="text-2xl font-bold">{warnings.length}</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Detailed Content */}
        <Tabs defaultValue="overview" className="space-y-4">
          <TabsList className="grid w-full grid-cols-5">
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="performance">Performance</TabsTrigger>
            <TabsTrigger value="security">Security</TabsTrigger>
            <TabsTrigger value="accessibility">Accessibility</TabsTrigger>
            <TabsTrigger value="network">Network</TabsTrigger>
          </TabsList>

          <TabsContent value="overview" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Card>
                <CardHeader>
                  <CardTitle>Form Analysis</CardTitle>
                  <CardDescription>
                    Form structure and field analysis
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <p className="text-sm font-medium text-muted-foreground">Form Count</p>
                        <p className="text-2xl font-bold">{metadata.form_count || 0}</p>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm font-medium text-muted-foreground">Total Fields</p>
                        <p className="text-2xl font-bold">{metadata.total_fields || 0}</p>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm font-medium text-muted-foreground">Required Fields</p>
                        <p className="text-2xl font-bold">{metadata.required_fields || 0}</p>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm font-medium text-muted-foreground">Field Types</p>
                        <p className="text-2xl font-bold">{Object.keys(metadata.field_types || {}).length}</p>
                      </div>
                    </div>
                    
                    <Separator />
                    
                    <div>
                      <h4 className="text-sm font-medium text-muted-foreground mb-2">Form Properties</h4>
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Method</span>
                          <Badge variant="outline" className="uppercase">{metadata.form_method || 'N/A'}</Badge>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Action</span>
                          <Badge variant="outline" className="max-w-[200px] truncate">
                            {metadata.form_action || 'N/A'}
                          </Badge>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Encoding</span>
                          <Badge variant="outline">{metadata.form_encoding || 'N/A'}</Badge>
                        </div>
                      </div>
                    </div>

                    {metadata.field_types && Object.keys(metadata.field_types).length > 0 && (
                      <>
                        <Separator />
                        <div>
                          <h4 className="text-sm font-medium text-muted-foreground mb-2">Field Types Distribution</h4>
                          <div className="space-y-2">
                            {Object.entries(metadata.field_types).map(([type, count]) => (
                              <div key={type} className="flex justify-between items-center">
                                <span className="text-sm capitalize">{type.replace(/_/g, ' ')}</span>
                                <Badge variant="secondary">{count}</Badge>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Form Features</CardTitle>
                  <CardDescription>
                    Security and validation capabilities
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-4">
                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Shield className="h-4 w-4" />
                          <span className="text-sm">CSRF Protection</span>
                        </div>
                        {metadata.security?.has_csrf_token ? (
                          <Badge variant="success">Protected</Badge>
                        ) : (
                          <Badge variant="destructive">Not Protected</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Lock className="h-4 w-4" />
                          <span className="text-sm">Secure Endpoint</span>
                        </div>
                        {metadata.security?.is_secure_endpoint ? (
                          <Badge variant="success">HTTPS</Badge>
                        ) : (
                          <Badge variant="destructive">HTTP</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Eye className="h-4 w-4" />
                          <span className="text-sm">Sensitive Fields</span>
                        </div>
                        {metadata.security?.has_sensitive_fields ? (
                          <Badge variant="warning">Present</Badge>
                        ) : (
                          <Badge variant="success">None</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <CheckCircle2 className="h-4 w-4" />
                          <span className="text-sm">Client Validation</span>
                        </div>
                        {metadata.validation?.has_client_validation ? (
                          <Badge variant="success">Enabled</Badge>
                        ) : (
                          <Badge>Disabled</Badge>
                        )}
                      </div>

                      {metadata.validation?.custom_validators && (
                        <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                          <div className="flex items-center gap-2">
                            <Code2 className="h-4 w-4" />
                            <span className="text-sm">Custom Validators</span>
                          </div>
                          <Badge variant="secondary">Present</Badge>
                        </div>
                      )}
                    </div>

                    {metadata.validation?.validation_types && metadata.validation?.validation_types.length > 0 && (
                      <>
                        <Separator />
                        <div>
                          <h4 className="text-sm font-medium text-muted-foreground mb-2">Validation Types</h4>
                          <div className="flex flex-wrap gap-2">
                            {metadata.validation.validation_types.map((type, index) => (
                              <Badge key={index} variant="outline">
                                {type}
                              </Badge>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Accessibility</CardTitle>
                  <CardDescription>
                    Form accessibility features
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-4">
                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Type className="h-4 w-4" />
                          <span className="text-sm">Input Labels</span>
                        </div>
                        {metadata.accessibility?.has_labels ? (
                          <Badge variant="success">Present</Badge>
                        ) : (
                          <Badge variant="destructive">Missing</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <TextCursor className="h-4 w-4" />
                          <span className="text-sm">Placeholders</span>
                        </div>
                        {metadata.accessibility?.has_placeholders ? (
                          <Badge variant="success">Present</Badge>
                        ) : (
                          <Badge>None</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <HelpCircle className="h-4 w-4" />
                          <span className="text-sm">Help Text</span>
                        </div>
                        {metadata.accessibility?.has_help_text ? (
                          <Badge variant="success">Present</Badge>
                        ) : (
                          <Badge>None</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Smartphone className="h-4 w-4" />
                          <span className="text-sm">Mobile Friendly</span>
                        </div>
                        {metadata.accessibility?.is_mobile_friendly ? (
                          <Badge variant="success">Yes</Badge>
                        ) : (
                          <Badge variant="warning">No</Badge>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Network Analysis</CardTitle>
                  <CardDescription>
                    Network requests and asset sizes
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <p className="text-sm font-medium text-muted-foreground">Total Requests</p>
                        <p className="text-2xl font-bold">{metadata.network?.request_count || 0}</p>
                      </div>
                      <div className="space-y-2">
                        <p className="text-sm font-medium text-muted-foreground">Total Size</p>
                        <p className="text-2xl font-bold">
                          {formatBytes(metadata.network?.asset_sizes?.total || 0)}
                        </p>
                      </div>
                    </div>

                    <Separator />

                    <div>
                      <h4 className="text-sm font-medium text-muted-foreground mb-2">Asset Breakdown</h4>
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Images</span>
                          <Badge variant="outline">
                            {formatBytes(metadata.network?.asset_sizes?.images || 0)}
                          </Badge>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Scripts</span>
                          <Badge variant="outline">
                            {formatBytes(metadata.network?.asset_sizes?.scripts || 0)}
                          </Badge>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Styles</span>
                          <Badge variant="outline">
                            {formatBytes(metadata.network?.asset_sizes?.styles || 0)}
                          </Badge>
                        </div>
                      </div>
                    </div>

                    {metadata.network?.redirect_chain && metadata.network.redirect_chain.length > 0 && (
                      <>
                        <Separator />
                        <div>
                          <h4 className="text-sm font-medium text-muted-foreground mb-2">Redirect Chain</h4>
                          <div className="space-y-2">
                            {metadata.network.redirect_chain.map((url, index) => (
                              <div key={index} className="flex items-center gap-2">
                                <ArrowRight className="h-4 w-4 text-muted-foreground" />
                                <span className="text-sm truncate">{url}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </CardContent>
              </Card>
            </div>
          </TabsContent>

          <TabsContent value="performance" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Card>
                <CardHeader>
                  <CardTitle>Loading Performance</CardTitle>
                  <CardDescription>
                    Page loading and form submission metrics
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-4">
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Time to First Byte</span>
                          <span className="font-medium">{metadata.performance?.time_to_first_byte || 0}ms</span>
                        </div>
                        <div className="w-full bg-secondary rounded-full h-2">
                          <div 
                            className="bg-primary rounded-full h-2" 
                            style={{ 
                              width: `${Math.min(100, ((metadata.performance?.time_to_first_byte || 0) / 1000) * 100)}%` 
                            }} 
                          />
                        </div>
                      </div>

                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Page Load Time</span>
                          <span className="font-medium">{metadata.performance?.page_load_time || 0}ms</span>
                        </div>
                        <div className="w-full bg-secondary rounded-full h-2">
                          <div 
                            className="bg-primary rounded-full h-2" 
                            style={{ 
                              width: `${Math.min(100, ((metadata.performance?.page_load_time || 0) / 2000) * 100)}%` 
                            }} 
                          />
                        </div>
                      </div>

                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Form Submission Time</span>
                          <span className="font-medium">{metadata.performance?.form_submission_time || 0}ms</span>
                        </div>
                        <div className="w-full bg-secondary rounded-full h-2">
                          <div 
                            className="bg-primary rounded-full h-2" 
                            style={{ 
                              width: `${Math.min(100, ((metadata.performance?.form_submission_time || 0) / 1000) * 100)}%` 
                            }} 
                          />
                        </div>
                      </div>
                    </div>

                    <Separator />

                    <div>
                      <h4 className="text-sm font-medium text-muted-foreground mb-2">Performance Summary</h4>
                      <div className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Total Test Duration</span>
                          <Badge variant="outline">{testResult.test_duration}ms</Badge>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="text-sm">Response Code</span>
                          <Badge 
                            variant={testResult.response_code >= 200 && testResult.response_code < 300 ? "success" : "destructive"}
                          >
                            {testResult.response_code || 'N/A'}
                          </Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Resource Usage</CardTitle>
                  <CardDescription>
                    Memory and DOM metrics
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="h-[300px]">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={[
                        {
                          name: "Memory Usage",
                          value: parseInt(performance.JSHeapUsedSize?.replace("MB", "") || "0"),
                          unit: "MB"
                        },
                        {
                          name: "DOM Nodes",
                          value: performance.Nodes || 0,
                          unit: "nodes"
                        },
                        {
                          name: "Script Duration",
                          value: performance.ScriptDuration || 0,
                          unit: "ms"
                        }
                      ]}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip 
                          formatter={(value, name, props) => [
                            `${value} ${props.payload.unit}`,
                            name
                          ]}
                        />
                        <Bar dataKey="value" fill={COLORS.info} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </CardContent>
              </Card>
            </div>
          </TabsContent>

          <TabsContent value="security" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Card>
                <CardHeader>
                  <CardTitle>Security Features</CardTitle>
                  <CardDescription>
                    Security features and vulnerabilities
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-4">
                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Shield className="h-4 w-4" />
                          <span className="text-sm">CSRF Protection</span>
                        </div>
                        {metadata.security?.has_csrf_token ? (
                          <Badge variant="success">Protected</Badge>
                        ) : (
                          <Badge variant="destructive">Not Protected</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Lock className="h-4 w-4" />
                          <span className="text-sm">Secure Endpoint</span>
                        </div>
                        {metadata.security?.is_secure_endpoint ? (
                          <Badge variant="success">HTTPS</Badge>
                        ) : (
                          <Badge variant="destructive">HTTP</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Eye className="h-4 w-4" />
                          <span className="text-sm">Sensitive Fields</span>
                        </div>
                        {metadata.security?.has_sensitive_fields ? (
                          <Badge variant="warning">Present</Badge>
                        ) : (
                          <Badge variant="success">None</Badge>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Vulnerabilities</CardTitle>
                  <CardDescription>
                    List of potential security vulnerabilities
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <ScrollArea className="h-[300px] pr-4">
                    {metadata.security?.vulnerabilities && metadata.security.vulnerabilities.length > 0 && (
                      <ul className="space-y-2">
                        {metadata.security.vulnerabilities.map((vulnerability, index) => (
                          <li key={index} className="text-sm border-l-2 border-red-500 pl-3">
                            {vulnerability}
                          </li>
                        ))}
                      </ul>
                    )}
                    
                    {!metadata.security?.vulnerabilities || metadata.security.vulnerabilities.length === 0 && (
                      <div className="flex items-center justify-center h-full text-muted-foreground">
                        No vulnerabilities found
                      </div>
                    )}
                  </ScrollArea>
                </CardContent>
              </Card>
            </div>
          </TabsContent>

          <TabsContent value="accessibility" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <Card>
                <CardHeader>
                  <CardTitle>Accessibility Features</CardTitle>
                  <CardDescription>
                    Accessibility features and issues
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 gap-4">
                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Type className="h-4 w-4" />
                          <span className="text-sm">Input Labels</span>
                        </div>
                        {metadata.accessibility?.has_labels ? (
                          <Badge variant="success">Present</Badge>
                        ) : (
                          <Badge variant="destructive">Missing</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <TextCursor className="h-4 w-4" />
                          <span className="text-sm">Placeholders</span>
                        </div>
                        {metadata.accessibility?.has_placeholders ? (
                          <Badge variant="success">Present</Badge>
                        ) : (
                          <Badge>None</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <HelpCircle className="h-4 w-4" />
                          <span className="text-sm">Help Text</span>
                        </div>
                        {metadata.accessibility?.has_help_text ? (
                          <Badge variant="success">Present</Badge>
                        ) : (
                          <Badge>None</Badge>
                        )}
                      </div>

                      <div className="flex items-center justify-between p-2 rounded-lg bg-background">
                        <div className="flex items-center gap-2">
                          <Smartphone className="h-4 w-4" />
                          <span className="text-sm">Mobile Friendly</span>
                        </div>
                        {metadata.accessibility?.is_mobile_friendly ? (
                          <Badge variant="success">Yes</Badge>
                        ) : (
                          <Badge variant="warning">No</Badge>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Accessibility Issues</CardTitle>
                  <CardDescription>
                    List of potential accessibility issues
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <ScrollArea className="h-[300px] pr-4">
                    {metadata.accessibility?.issues && metadata.accessibility.issues.length > 0 && (
                      <ul className="space-y-2">
                        {metadata.accessibility.issues.map((issue, index) => (
                          <li key={index} className="text-sm border-l-2 border-red-500 pl-3">
                            {issue}
                          </li>
                        ))}
                      </ul>
                    )}
                    
                    {!metadata.accessibility?.issues || metadata.accessibility.issues.length === 0 && (
                      <div className="flex items-center justify-center h-full text-muted-foreground">
                        No issues found
                      </div>
                    )}
                  </ScrollArea>
                </CardContent>
              </Card>
            </div>
          </TabsContent>

          <TabsContent value="network" className="space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Network Analysis</CardTitle>
                <CardDescription>
                  Network requests and asset sizes
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-6">
                  <div className="flex items-center gap-4">
                    <div className="w-32 text-sm font-medium text-muted-foreground">Total Requests</div>
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4 text-muted-foreground" />
                      <span>{metadata.network?.request_count || 0}</span>
                    </div>
                  </div>
                  <Separator />
                  
                  <div className="flex items-center gap-4">
                    <div className="w-32 text-sm font-medium text-muted-foreground">Total Size</div>
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4 text-muted-foreground" />
                      <span>
                        {formatBytes(metadata.network?.asset_sizes?.total || 0)}
                      </span>
                    </div>
                  </div>
                  <Separator />
                  
                  <div>
                    <h4 className="text-sm font-medium text-muted-foreground mb-2">Asset Breakdown</h4>
                    <div className="space-y-2">
                      <div className="flex justify-between items-center">
                        <span className="text-sm">Images</span>
                        <Badge variant="outline">
                          {formatBytes(metadata.network?.asset_sizes?.images || 0)}
                        </Badge>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-sm">Scripts</span>
                        <Badge variant="outline">
                          {formatBytes(metadata.network?.asset_sizes?.scripts || 0)}
                        </Badge>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-sm">Styles</span>
                        <Badge variant="outline">
                          {formatBytes(metadata.network?.asset_sizes?.styles || 0)}
                        </Badge>
                      </div>
                    </div>
                  </div>

                  {metadata.network?.redirect_chain && metadata.network.redirect_chain.length > 0 && (
                    <>
                      <Separator />
                      <div>
                        <h4 className="text-sm font-medium text-muted-foreground mb-2">Redirect Chain</h4>
                        <div className="space-y-2">
                          {metadata.network.redirect_chain.map((url, index) => (
                            <div key={index} className="flex items-center gap-2">
                              <ArrowRight className="h-4 w-4 text-muted-foreground" />
                              <span className="text-sm truncate">{url}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

export default TestReports;
